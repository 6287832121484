import {store} from '../redux/store';
import {showLoaderAC} from '../redux/action/serviceParameters';
import {decodeCarPhotoAC, getCarOnVinInputAC} from '../redux/action/car';
import {setImageDataAC} from '../redux/action/scanner';
//types
import Part from '../entity/Part';
import {
    carBrandLogo,
    carBrandLogoDefault
} from '../config/constant/CarBrand';
import {HTTP_STATUS_CODE} from '../api/type/Api';
import {Car} from "../entity/Car";
import {SearchHistory} from "../entity/SearchHistory";
//services
import {ServiceParametersService} from './ServiceParametersService';


/**
 * Service util to process car data
 */
export class CarService {
    /**
     * Method to get car info on input vin
     * @param vinCode
     */
    static async requestCarInfoOnVin(vinCode: string): Promise<void> {
        store.dispatch(showLoaderAC(true));
        // @ts-ignore
        if (vinCode.length > 0) await store.dispatch(getCarOnVinInputAC(vinCode));
        store.dispatch(showLoaderAC(false));
    }

    /**
     * Method to get car info on uploaded car-passport photo
     * @param base64Image
     * @param repeat
     */
    static async requestCarInfoOnPhoto(base64Image: string | ArrayBuffer | null, repeat: boolean = false): Promise<void> {
        if (base64Image) {
            store.dispatch(showLoaderAC(true));
            store.dispatch(setImageDataAC(base64Image));
            // @ts-ignore
            await store.dispatch(decodeCarPhotoAC(base64Image, repeat));
            store.dispatch(showLoaderAC(false));
        }
    }

    /**
     * Method to process car data after photo decode
     * @param data
     */
    static processPhotoDecodedCarData(data: Array<any>): Array<any> {
        const decodedCarDataList: Array<any> = [];

        if (data) {
            if (data.length === 0) {
                const noCar = new Car(
                    '',
                    'noCar',
                    'No results found for this document',
                    '',
                    '',
                    null,
                );
                noCar.logo = CarService.getCarBrandLogo('');
                decodedCarDataList.push(noCar);
            }

            data.forEach(carData => {
                const car = new Car(
                    carData?.markName,
                    carData?.autoHash,
                    carData?.displayName,
                    carData?.yearOfRelease,
                    carData?.fuel,
                    null
                );
                car.logo = CarService.getCarBrandLogo(carData?.markName);

                decodedCarDataList.push(car);
            });
        }

        return decodedCarDataList;
    }

    /**
     * Method to process car data on vIN enter
     * @param data
     */
    static processDecodedCarData(data: any): Array<Car> {
        let decodedCarDataList: Array<Car> = [];

        if (data && data.code === HTTP_STATUS_CODE.OK) {
            const cars = data.data ? data.data : [];

            if (data) {
                decodedCarDataList = cars.map((carData: any) => {
                    const car = new Car(
                        carData?.markName,
                        carData?.autoHash,
                        carData?.displayName,
                        carData?.yearOfRelease,
                        carData?.fuel,
                        null
                    );
                    car.logo = CarService.getCarBrandLogo(carData?.markName);

                    return car;
                });
            }
        } else {
            ServiceParametersService.processDataForNotificationModal(data);
        }

        return decodedCarDataList;
    }

    /**
     * Method which generates car logo icon path to assets
     * @param markName
     */
    static getCarBrandLogo(markName: string): string {
        let carLogoPath: string = carBrandLogoDefault.path;
        for (const carLogoData in carBrandLogo) {
            if (carBrandLogo[carLogoData].markName === markName.toUpperCase()) {
                carLogoPath = carBrandLogo[carLogoData].path;
            }
        }

        return carLogoPath;
    }

    /**
     * Method to update car parts
     * @param cars
     * @param parts
     * @param autoHash
     */
    static updatePartsOnCar(cars: Array<Car>, parts: Array<Part>, autoHash: string): Array<Car> {
        let updatedCars: Array<Car> = [...cars]

        updatedCars = updatedCars.map(car => {
            if (car.parts.length === 0 && car.autoHash === autoHash) car.parts = parts;
            return car;
        })

        return updatedCars;
    }

    /**
     * Method to update car parts for car list on search page
     * @param searchHistories
     * @param data
     */
    static updatePartsOnCarForSearchHistory(searchHistories: Array<SearchHistory>, data: any): Array<SearchHistory> {
        const {
            autoHash,
            historyId,
            parts
        } = data;
        let updatedHistories: Array<SearchHistory> = [...searchHistories];

        updatedHistories.forEach((history: SearchHistory) => {
            if (history.id === historyId) {
                // @ts-ignore
                let historyCars: Array<Car> = [...history.details];
                historyCars.forEach((car: Car) => {
                    if (car.autoHash === autoHash) {
                        car.parts = parts;
                    }
                });
                history.details = historyCars;
            }
        });

        return updatedHistories;
    }
}