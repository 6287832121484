import {RootState} from '../type/RootStore';
import {ModalData} from '../../entity/ModalData';


interface ServiceParametersTypes extends RootState {
    collapsedCars: Array<string>,
    showLoader: boolean,
    showPartsLoader: boolean,
    notificationModalVisible: boolean,
    notificationModalData: ModalData,
    userLang: string,
    offerModalDisplayed: boolean,
    websocketConnection: object | undefined
    authCodeInputVisible: boolean
}

export const collapsedCarsCodes = (state: ServiceParametersTypes): Array<string> => state.serviceParameters.collapsedCars;
export const showLoaderIndicator = (state: ServiceParametersTypes): boolean => state.serviceParameters.showLoader;
export const showPartsLoaderIndicator = (state: ServiceParametersTypes): boolean => state.serviceParameters.showPartsLoader;
export const notificationModalVisible = (state: ServiceParametersTypes): boolean => state.serviceParameters.notificationModalVisible;
export const notificationModalData = (state: ServiceParametersTypes): ModalData => state.serviceParameters.notificationModalData;
export const offerModalDisplayed = (state: ServiceParametersTypes): boolean => state.serviceParameters.offerModalDisplayed;
export const authCodeInputVisibleSelector = (state: ServiceParametersTypes): boolean => state.serviceParameters.authCodeInputVisible;

// cached parameters
export const userLang = (state: ServiceParametersTypes): string => state.cachedServiceParameters.userLang;
export const websocketConnectionSelector = (state: ServiceParametersTypes): object | undefined => state.cachedServiceParameters.websocketConnection;
