import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

import {ApiError} from "./type/ApiError";
import {API, API_MOCK, API_QA} from "./type/Api";

import {isDevelopmentMode} from "../util/EnvUtility";
import {getAuthHeaders} from "./apiUtil";


class ApiClient {
    private axiosInstance: AxiosInstance;

    constructor() {
        const baseURL: string =
            isDevelopmentMode() ? API_MOCK.REQUEST_SERVICE_URL : API_QA.REQUEST_SERVICE_URL;

        /**
         * Axios init with base params
         */
        this.axiosInstance = axios.create({
            baseURL: `${baseURL}${API.VERSION}`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        /**
         * Inject base and auth headers
         */
        // @ts-ignore
        this.axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
            if (!config.headers) {
                config.headers = {};
            }

            const authHeaders: object = getAuthHeaders();
            Object.assign(config.headers, authHeaders);

            return config;
        });

        /**
         * Error handling
         */
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error) => {
                const {response} = error;
                const status = response?.status || 500;
                const message = response?.data?.message || 'An unknown error occurred';
                return Promise.reject(new ApiError(status, message, response?.data?.details));
            }
        );
    }

    /**
     * @GET
     * @param url
     * @param config
     */
    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.get<T>(url, config);
        return response.data;
    }

    /**
     * @POST
     * @param url
     * @param data
     * @param config
     */
    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.post<T>(url, data, config);
        return response.data;
    }

    /**
     * @PUT
     * @param url
     * @param data
     * @param config
     */
    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.put<T>(url, data, config);
        return response.data;
    }

    /**
     * @DELETE
     * @param url
     * @param config
     */
    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.delete<T>(url, config);
        return response.data;
    }
}

export const apiClient = new ApiClient();