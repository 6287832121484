import {CachedServiceParametersActions} from "../action/cachedServiceParameters";

const initialState = {
    userLang: 'ro',
    websocketConnection: {},
}

const cachedServiceParameters = (state = initialState, action: any): any => {
    switch (action.type) {
        //TODO move to user reducer
        case CachedServiceParametersActions.SET_USER_LANG: {
            return {
                ...state,
                userLang: action.payload
            }
        }
        case CachedServiceParametersActions.SET_ACTIVE_WEBSOCKET_CONNECTION: {
            return {
                ...state,
                websocketConnection: action.payload
            }
        }
        default:
            return state;
    }
}

export default cachedServiceParameters;