import React, {FC} from 'react';
import {Avatar} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {MessageType} from "../../MessageType";
import {UserRole} from "../../../../../entity/enums/UserRole";

import './message_avatar.css'


interface ChatMessageAvatarProps {
    userRole: string;
    avatarClassName: string;
    username: string | undefined;
    avatarType: MessageType
}


const ChatMessageAvatar: FC<ChatMessageAvatarProps> = ({avatarClassName, username, avatarType, userRole}) => {
    const clientAvatar: JSX.Element = <>
        <Avatar
            size="large"
            style={{backgroundColor: '#f6f5f5', color: '#3b3b3b'}}
            icon={<UserOutlined/>}
            className={avatarClassName}
        />
        <h4>{username}</h4>
    </>;

    const operatorAvatar: JSX.Element =
        <>
            <Avatar
                size="large"
                style={{backgroundColor: '#000000'}}
                className={avatarClassName}
            >
                iA
            </Avatar>
            <h4>{username}</h4>
        </>;

    return <>
        {avatarType === MessageType.FROM ?
            <div className='iauto-message-avatar-container'>
                {userRole === UserRole.ROLE_CLIENT.toString() ? clientAvatar : operatorAvatar}
            </div>
            :
            <div className='iauto-message-avatar-container'>
                {userRole === UserRole.ROLE_CLIENT.toString() ? operatorAvatar : clientAvatar}
            </div>
        }
    </>
};

export default ChatMessageAvatar;
