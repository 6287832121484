import {RootState} from '../type/RootStore';
import {AuthenticatedUser, User} from "../../entity/type/User";


interface UserSelectorTypes extends RootState {
    userData: AuthenticatedUser,
    mockUser: User
}

export const userDataSelector = (state: UserSelectorTypes) => state.user.userData;
export const userUpdateSelector = (state: UserSelectorTypes) => state.user.userUpdateInProgress;
export const authLoading = (state: UserSelectorTypes) => state.user.userAuthLoading;

export const mockUserDataSelector = (state: UserSelectorTypes) => state.user.mockUserData;
