import {Dispatch} from "redux";
import axios, {AxiosResponse} from "axios";
//services
import LocalStorageService from "../../security/LocalStorageService";
import {ServiceParametersService} from "../../util/ServiceParametersService";
//types
import {API} from "../../api/type/Api";


export enum SearchHistoryActions {
    GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY',
    GET_SEARCH_HISTORY_DETAILS = 'GET_SEARCH_HISTORY_DETAILS',
    ADD_COMMENT_TO_SEARCH_HISTORY = 'ADD_COMMENT_TO_SEARCH_HISTORY',
    DELETE_SEARCH_HISTORY_ITEM = 'DELETE_SEARCH_HISTORY_ITEM'
}

/**
 * GET List of search history items
 */
export const getUserSearchHistoryAC = () => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();

    // return axios.post(API.BASE_URL + API.VERSION + '/history',  {headers})
    return axios.get(API.VERSION + '/history',  {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: SearchHistoryActions.GET_SEARCH_HISTORY,
                payload: response?.data?.data?.historyData

            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

/**
 * GET detailed search history item
 * @param historyId
 */
export const getSearchHistoryDetailsAC = (historyId: number) => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();

    // return axios.post(API.BASE_URL + API.VERSION + '/history/' + historyId, {headers})
    return axios.get(API.VERSION + '/history/' + historyId, {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: SearchHistoryActions.GET_SEARCH_HISTORY_DETAILS,
                payload: response?.data?.data

            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

/**
 * PUT comment to search history item
 * @param historyId
 * @param comment
 */
export const addCommentToSearchHistoryAC = (historyId: number, comment: string = '') => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body: any = {
        comment: comment
    }
    // return axios.post(API.BASE_URL + API.VERSION + '/history/' + historyId, {headers})
    return axios.put(API.VERSION + '/history/' + historyId, body,{headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: SearchHistoryActions.ADD_COMMENT_TO_SEARCH_HISTORY,
                payload: response?.data?.data,
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

/**
 * DELETE Search history item by id
 * @param historyId
 */
export const deleteSearchHistoryItemAC = (historyId: number) => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();

    // return axios.post(API.BASE_URL + API.VERSION + '/history/' + historyId, {headers})
    return axios.delete(API.VERSION + '/history/' + historyId, {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: SearchHistoryActions.DELETE_SEARCH_HISTORY_ITEM,
                payload: response?.data?.data

            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}