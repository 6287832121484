import {SettingsActions} from '../action/settings';

const initialState = {
    language: 'ro'
}

const settings = (state = initialState, action: any): any => {
    switch (action.type) {
        case SettingsActions.SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload
            }
        }
        default:
            return state;
    }
}

export default settings;