import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Result} from "antd";
import {PATHS} from "../../../routing/type/Path";


const UnderConstructPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title="Under Construction"
            subTitle="Sorry, the page you visited does not exist or it is under construction."
            extra={
                <Link to="/">
                    <Button
                        type="primary"
                        onClick={() => navigate(PATHS.ROOT_PATH)}
                    >
                        Go Back Home
                    </Button>
                </Link>
            }
        />
    );
};

export default UnderConstructPage;