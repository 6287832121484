import {store} from "../redux/store";
import {
    addCommentToSearchHistoryAC,
    deleteSearchHistoryItemAC,
    getSearchHistoryDetailsAC,
    getUserSearchHistoryAC
} from "../redux/action/searchHistory";
//types
import {SearchHistory} from "../entity/SearchHistory";
import {Car} from "../entity/Car";


export class SearchHistoryService {
    static getUserSearchHistory(): void {
        // @ts-ignore
        store.dispatch(getUserSearchHistoryAC());
    }

    static getSearchHistoryDetails(historyId: number): void {
        // @ts-ignore
        store.dispatch(getSearchHistoryDetailsAC(historyId));
    }

    static async addCommentToSearchHistory(historyId: number, comment: string): Promise<any> {
        // @ts-ignore
        store.dispatch(addCommentToSearchHistoryAC(historyId, comment));
    }

    static async deleteSearchHistoryItem(historyId: number): Promise<any> {
        // @ts-ignore
        store.dispatch(deleteSearchHistoryItemAC(historyId));
    }

    /**
     * @param data from http-response
     */
    static mapSearchHistoryItems(data: Array<any>): Array<SearchHistory> {
        let searchHistory: Array<SearchHistory> = [];
        searchHistory = data?.map((searchHistoryItem: any) => {
            return new SearchHistory(
                searchHistoryItem.id,
                searchHistoryItem.date,
                searchHistoryItem.vin,
                null,
                searchHistoryItem.status,
                searchHistoryItem.modelName,
                searchHistoryItem.comment
            )
        });

        return searchHistory;
    }

    static addDetailsToSearchHistoryItem(searchHistoryData: Array<SearchHistory>, details: any): Array<SearchHistory> {
        let searchHistory: Array<SearchHistory> = [...searchHistoryData];
        const cars: Array<Car> = details?.response.map((car: any) => {
            return new Car(
                car.markName,
                car.autoHash,
                car.displayName,
                car.yearOfRelease,
                car.fuel,
                car.decodeResponce
            )
        })

        searchHistory.map((searchHistoryItem: SearchHistory) => {
            if (searchHistoryItem.id === details?.id) {
                searchHistoryItem.details = cars;
            }
        });

        return searchHistory;
    }

    static addCommentToSearchHistoryItem(searchHistoryData: Array<SearchHistory>, data: any): Array<SearchHistory> {
        const searchHistory: Array<SearchHistory> = [...searchHistoryData];
        searchHistory.map((searchHistoryItem: SearchHistory) => {
            if (searchHistoryItem.id === data?.id) {
                searchHistoryItem.comment = data.comment && data.comment;
            }
        });

        return searchHistory;
    };

    static deleteHistoryItemFromList (searchHistoryData: Array<SearchHistory>, data: any): Array<SearchHistory> {
        const searchHistory: Array<SearchHistory> = [...searchHistoryData];
        if (!data.id) {
            return  searchHistory;
        }

        return searchHistory.filter((searchHistoryItem: SearchHistory) => {
            return searchHistoryItem.id !== data.id
        });
    };
}