export enum CachedServiceParametersActions {
    SET_USER_LANG = 'SET_USER_LANG',
    SET_ACTIVE_WEBSOCKET_CONNECTION = 'SET_ACTIVE_WEBSOCKET_CONNECTION',
}

//TODO move to user action creators file
export const setUserLangAC = (langCode: string) => ({
    type: CachedServiceParametersActions.SET_USER_LANG,
    payload: langCode
});

export const setActiveWebsocketConnectionAC = (websocket: any) => ({
    type: CachedServiceParametersActions.SET_ACTIVE_WEBSOCKET_CONNECTION,
    payload: websocket
});