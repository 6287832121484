import React, {FC} from 'react';
import {Image} from 'antd';
import {MessageImage} from "../../../../entity/type/Message";
import {useTranslation} from "react-i18next";


interface MessageImgProps {
    text: string;
    imageData: MessageImage;
}

const ChatMessageImg: FC<MessageImgProps> = ({text, imageData}: MessageImgProps) => {
    const {t} = useTranslation();
    const decodedTextTitle: string = t('Text decoded from picture');
    const formattedText: string = text.replace(/\n/g, '<br>');

    return <>
        <Image width={100} src={imageData.imageUrl}/>
        {
            text.length > 0 &&
            <div className='iauto-message-image-text'>
                <div>{decodedTextTitle}</div>
                <span dangerouslySetInnerHTML={{__html: formattedText}}/>
            </div>
        }
    </>
};

export default ChatMessageImg;