export enum ImageActions {
    SAVE_MESSAGE_IMAGE = 'SAVE_MESSAGE_IMAGE'

}

export const saveMessageImageAC = () => async () => {
    // const requestConfig: object = {
    //     headers: UserService.getAuthHeaders()
    // };
    // const body = {
    //     clientId: userId
    // };
    //
    // // return axios.post(API.BASE_URL + API.VERSION + '/decode/parts', body, {headers})
    // return new Promise((resolve, reject): void => {
    //     axios.post(API_MOCK.REQUEST_SERVICE_URL + API.VERSION + '/requests', body, requestConfig)
    //         .then((response: AxiosResponse<any>): void => {
    //             dispatch({
    //                 type: RequestActions.CREATE_REQUEST,
    //                 payload: response?.data
    //             });
    //             if (setActive) {
    //                 dispatch({
    //                     type: RequestActions.SET_ACTIVE_REQUEST,
    //                     payload: response?.data
    //                 });
    //             }
    //             resolve(response?.data);
    //         })
    //         .catch(e => {
    //             ServiceParametersService.processDataForNotificationModal(e.response.data);
    //             reject(e);
    //         });
    // });
};