import {store} from "../redux/store";
import {
    displayOfferModalAC,
    openNotificationModalAC,
    setNotificationModalDataAC
} from '../redux/action/serviceParameters';
import {setActiveWebsocketConnectionAC} from "../redux/action/cachedServiceParameters";
//services
import {CarService} from "./CarService";
//types
import {HTTP_STATUS_CODE} from '../api/type/Api';
import {ModalData, ErrorDetail} from '../entity/ModalData';
import {Car} from "../entity/Car";
import {RootState} from "../redux/type/RootStore";


export class ServiceParametersService {
    static getCollapsedCars(cars: Array<Car> = []): Array<string> {
        return cars.map(carData => carData.autoHash);
    }

    /**
     * Method to control notification modal state and behaviour
     * @param error
     * @param message
     */
    //TODO create NotificationModalService and move this functionality there
    static processDataForNotificationModal(error: any, message?: string): void {
        const data = error?.response?.data ?? {
            status: 'ERROR',
            code: 500,
            message: 'An unknown network error occurred',
            error: { details: 'No response data available' }
        };

        const state: RootState = store.getState();
        const base64Image: string = state.scanner.image;
        let modalData: ModalData = new ModalData(
            data.status,
            data.code,
            message ? message : data.message,
            new ErrorDetail(data.error?.details)
        );

        switch (data?.code) {
            case(HTTP_STATUS_CODE.NOT_FOUND):
                store.dispatch(setNotificationModalDataAC(modalData));
                break;
            case(HTTP_STATUS_CODE.RETRY):
                modalData.onOk = () => CarService.requestCarInfoOnPhoto(base64Image, true);
                store.dispatch(setNotificationModalDataAC(modalData));
                break;
            case(HTTP_STATUS_CODE.I_M_TEAPOT):
                modalData.onOk = () => {};
                store.dispatch(setNotificationModalDataAC(modalData));
                break;
            case(HTTP_STATUS_CODE.UNSUPPORTED_MEDIA_TYPE):
                store.dispatch(setNotificationModalDataAC(modalData));
                break;
            default:
                modalData.onOk = () => {}
                store.dispatch(setNotificationModalDataAC(modalData));
                break;
        }

        store.dispatch(openNotificationModalAC());
    }

    static displayOfferDrawer(display: boolean): void {
        store.dispatch(displayOfferModalAC(display));
    }

    static saveWebsocket(websocket: object | undefined): void {
        store.dispatch(setActiveWebsocketConnectionAC(websocket));
    }
}

