import {closeNotificationModalAC} from '../redux/action/serviceParameters';


class ModalData {
    private _status: string;
    private _code: string;
    private _message: string;
    private _error: ErrorDetail;
    private _onOk: any;
    private _onCancel: any;

    constructor(status: string, code: string, message: string, error: any) {
        this._status = status;
        this._code = code;
        this._message = message;
        this._error = error;
        this._onOk = null;
        this._onCancel = closeNotificationModalAC;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get error(): any {
        return this._error;
    }

    set error(value: any) {
        this._error = value;
    }

    get onOk(): any {
        return this._onOk;
    }

    set onOk(value: any) {
        this._onOk = value;
    }

    get onCancel(): any {
        return this._onCancel;
    }

    set onCancel(value: any) {
        this._onCancel = value;
    }
}


class ErrorDetail {
    private _detail: string | Array<any>;

    constructor(detail: string) {
        this._detail = detail;
    }

    get detail(): string | Array<any> {
        return this._detail;
    }

    set detail(value: string | Array<any>) {
        this._detail = value;
    }
}


export {
    ModalData,
    ErrorDetail
};