import React, { FC } from 'react';
import { Col, Row } from 'antd';
import MenuCard, { CardData } from './card/MenuCard';
import { BASIC_CARD_DATA, LARGE_CARD_DATA } from './card/cardData';

import './main.css';

const MainPage: FC = () => {
    return (
        <div className='iauto-main-container'>
            <Row justify='space-between' className='iauto-card-row'>
                <Col xs={0} sm={2} md={2} lg={4} xl={4} />
                {BASIC_CARD_DATA.map((card: CardData) => (
                    <MenuCard key={card.to} {...card} />
                ))}
                <Col xs={0} sm={2} md={2} lg={4} xl={4} />
            </Row>
            <Row justify='center' className='iauto-card-row'>
                <Col xs={2} sm={2} md={2} lg={4} xl={4} />
                {LARGE_CARD_DATA.map((card: CardData) => (
                    <MenuCard key={card.to} {...card} />
                ))}
                <Col xs={2} sm={2} md={2} lg={4} xl={4} />
            </Row>
        </div>
    );
};

export default MainPage;
