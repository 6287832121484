import React, {FC} from 'react';
import useIsAuthenticated from "../security/hook/useIsAuthenticated";

import {Navigate, Outlet, useLocation} from 'react-router-dom';


const ProtectedRoute: FC = () => {
    const isAuthenticated = useIsAuthenticated();
    let location = useLocation();

    if (!isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    return <Outlet/>;
};

export default ProtectedRoute;
