import React from 'react';
import dayjs from "dayjs";
import {processImageFile} from "../../../../../../../../util/image/ImageUtil";
import {validatePrice, validateQuantity} from "../../../../../../../../util/form/formValidationRules";

import {Button, Col, DatePicker, Divider, Form, Input, Row, Upload} from 'antd';
import {CloseCircleFilled, UploadOutlined} from "@ant-design/icons";

import {OfferPosition} from "../../../../../../../../entity/type/OfferPosition";

import './offer_position.css'
import {useTranslation} from "react-i18next";


interface OfferPositionProps {
    positionData: OfferPosition;
    updatePosition: (positionData: OfferPosition) => void;
    onDeletePosition: (positionIndex: number) => void;
    offerNumber: number;
}

const OfferPositionItem: React.FC<OfferPositionProps> = ({
                                                             positionData,
                                                             updatePosition,
                                                             onDeletePosition,
                                                             offerNumber
                                                         }) => {
    const {t} = useTranslation();
    const positionHeader: string = `${t('Position')} ${offerNumber + 1}`;
    const onDeletePositionButtonClick = (): void => {
        onDeletePosition(offerNumber);
    };

    const handleFieldChange = (fieldName: string, fieldValue: any) => {
        let updatedPositionData: OfferPosition = {...positionData};

        if (fieldName === 'deliveryTime') {
            const startDate: string = fieldValue[0] ? dayjs(fieldValue[0], 'YYYY-MM-DD', true)
                .format('YYYY-MM-DD') : '';
            const endDate: string = fieldValue[1] ? dayjs(fieldValue[1], 'YYYY-MM-DD', true)
                .format('YYYY-MM-DD') : '';

            updatedPositionData = {
                ...positionData,
                deliveryTime: [startDate, endDate],
            };
        } else {
            updatedPositionData = {
                ...positionData,
                [fieldName]: fieldValue,
            };
        }

        updatePosition(updatedPositionData);
    };

    const handleImageUpload = async (file: File) => {
        try {
            const base64String: string | null = await processImageFile(file);

            if (base64String) {
                const images: Array<{
                    image: string;
                    image_base64: string
                }> = [{
                    image: file?.type,
                    image_base64: base64String,
                }];

                const updatedPositionData = {
                    ...positionData,
                    images,
                };
                updatePosition(updatedPositionData);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    return (
        <div>
            <Row gutter={[16, 16]} align="middle">
                <Col xs={20} sm={22}>
                    <h3>{positionHeader}</h3>
                </Col>
                <Col xs={4} sm={2} style={{textAlign: 'right'}}>
                    <Button icon={<CloseCircleFilled/>}
                            size='large'
                            danger
                            style={{border: 'none'}}
                            onClick={onDeletePositionButtonClick}
                    >
                    </Button>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`productName[${offerNumber}]`}
                        label={t('Product name')}
                        rules={[
                            {required: true, message: t('Product name mes')},
                            {max: 30, message: t('Max length name mes')}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Product name label')}
                            name="productName"
                            value={positionData?.productName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`brand[${offerNumber}]`}
                        label={t('brand')}
                        rules={[
                            {required: true, message: t('Brand name mes')},
                            {max: 30, message: t('Max length brand mes')}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Brand name label')}
                            name="brand"
                            value={positionData?.brand}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`description[${offerNumber}]`}
                        label={t('Description')}
                        rules={[
                            {required: false, message: t('Description mes')},
                            {max: 30, message: t('Max length description mes')}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Description label')}
                            name="description"
                            value={positionData?.description}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={6}>
                    <Form.Item
                        name={`price[${offerNumber}]`}
                        label={t('Price')}
                        rules={[
                            {required: true, message: t('Enter price mes')},
                            {validator: (_, value) => validatePrice(value)}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Price placeholder')}
                            name="price"
                            value={positionData?.price}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <Form.Item
                        name={`quantity[${offerNumber}]`}
                        label={t('Quantity')}
                        rules={[
                            {required: true, message: t('Enter quantity mes')},
                            {validator: (_, value) => validateQuantity(value)}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Enter quantity placeholder')}
                            name="quantity"
                            value={positionData?.quantity}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`deliveryTime[${offerNumber}]`}
                        label={t('Delivery time')}
                        rules={[
                            {
                                required: false,
                                message: t('Select date time mes')
                            }
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <DatePicker.RangePicker
                            style={{width: '100%'}}
                            getPopupContainer={(trigger) => trigger.parentElement!}
                            value={
                                positionData?.deliveryTime
                                    ? [
                                        dayjs(positionData.deliveryTime[0], 'YYYY-MM-DD'),
                                        dayjs(positionData.deliveryTime[1], 'YYYY-MM-DD')
                                    ]
                                    : null
                            }
                            onChange={(_dates, stringDates) => {
                                handleFieldChange('deliveryTime', stringDates);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`productCode[${offerNumber}]`}
                        label={t('Product code')}
                        rules={[
                            {required: false, message: t('Enter product code mes')},
                            {max: 30, message: t('"Max length product code mes"')}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Enter product code placeholder')}
                            name="productCode"
                            value={positionData?.productCode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name={`supplier[${offerNumber}]`}
                        label={t('Supplier')}
                        rules={[
                            {required: true, message: t('Enter supplier name mes')},
                            {max: 30, message: t('Max length supplier mes')}
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input
                            placeholder={t('Enter supplier name placeholder')}
                            name="supplier"
                            value={positionData?.supplier}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFieldChange(e.target.name, e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={16} md={16}>
                    <Form.Item
                        name={`notes[${offerNumber}]`}
                        label={t('Notes')}
                        rules={[
                            {
                                required: false,
                                message: t('Enter addition notes mes'),
                            },
                        ]}
                        style={{marginBottom: 10}}
                    >
                        <Input.TextArea
                            rows={4}
                            value={positionData?.notes}
                            placeholder={t('Additional notes placeholder')}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                handleFieldChange('notes', e.target.value)
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8}>
                    <Form.Item
                        name={`imageUpload[${offerNumber}]`}
                        label={t('Image Upload')}
                        style={{marginBottom: 10}}
                    >
                        <Upload
                            beforeUpload={(file) => {
                                handleImageUpload(file);
                                return false;
                            }}
                            listType="picture"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined/>}>{t('Image Upload')}</Button>
                        </Upload>
                    </Form.Item>
                </Col>
            </Row>
            <Divider/>
        </div>
    );
};

export default OfferPositionItem;
