import React, {FC, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {userDataSelector, userUpdateSelector} from "../../../redux/selector/userSelector";
import {useTranslation} from "react-i18next";

import UserService from "../../../service/UserService";

import {Button, Col, Descriptions, Divider, Input, Row} from 'antd';
import {AuthenticatedUser, User} from "../../../entity/type/User";

import './my_profile.css';


const UserProfilePage: FC = () => {
    const userData: AuthenticatedUser = useSelector(userDataSelector);
    const user: User = userData?.user;
    const firstName: string = user?.firstName || '-';
    const lastName: string = user?.lastName || '-';
    const phone: string = user?.phoneNumber || '-';
    const mail: string = user?.mail || '-';

    const userUpdateInProgress: boolean = useSelector(userUpdateSelector);
    const [editModeActive, setEditModeActive] = useState(false);
    const [editedUserData, setEditedUserData] = useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
        mail: user?.mail
    });
    const {t} = useTranslation();

    useEffect((): void => {
        setEditedUserData({firstName: user.firstName || '', lastName: user.lastName || '', mail: user.mail || ''});
    }, [user, editModeActive]);

    const handleEditToggle = (): void => {
        setEditModeActive(!editModeActive);
    };

    const handleInputChange = (key: keyof User, value: string): void => {
        setEditedUserData((prevValues) => ({...prevValues, [key]: value}));
    };

    const handleSave = async (): Promise<void> => {
        UserService.setUserUpdateInProgressFlag(true);
        await UserService.updateUserProfileData(editedUserData);
        UserService.setUserUpdateInProgressFlag(false);
        setEditModeActive(false);
    };

    return (
        <Row justify="center" className="user-profile-row">
            <Col xs={24} sm={20} md={16} lg={12} xl={10} xxl={8}>
                <Divider style={{fontSize: 24, borderColor: "#0951a9"}}>{t('User Profile')}</Divider>
                <div className="iauto-user-profile-wrapper">
                    <Descriptions
                        column={1}
                        size="middle"
                        bordered={false}
                        className="user-profile-descriptions"
                        labelStyle={{fontSize: '16px'}}
                    >
                        <Descriptions.Item label={t('First Name')}
                                           contentStyle={{fontSize: '16px', fontWeight: 'bold'}}>
                            {editModeActive ? (
                                <Input
                                    value={editedUserData.firstName}
                                    disabled={userUpdateInProgress}
                                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                                />
                            ) : (
                                <b>{firstName}</b>
                            )}
                        </Descriptions.Item>
                        <Divider/>
                        <Descriptions.Item label={t('Last Name')} contentStyle={{fontSize: '16px', fontWeight: 'bold'}}>
                            {editModeActive ? (
                                <Input
                                    value={editedUserData.lastName}
                                    disabled={userUpdateInProgress}
                                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                                />
                            ) : (
                                <b>{lastName}</b>
                            )}
                        </Descriptions.Item>
                        <Divider/>
                        <Descriptions.Item label={t('Phone Number')}
                                           contentStyle={{fontSize: '16px', fontWeight: 'bold'}}>
                            <b>{phone}</b>
                        </Descriptions.Item>
                        <Divider/>
                        <Descriptions.Item label={t('Email')} contentStyle={{fontSize: '16px', fontWeight: 'bold'}}>
                            {editModeActive ? (
                                <Input
                                    value={editedUserData.mail}
                                    disabled={userUpdateInProgress}
                                    onChange={(e) => handleInputChange('mail', e.target.value)}
                                />
                            ) : (
                                <b>{mail}</b>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                <div className="user-profile-actions">
                    {editModeActive ? (
                        <>
                            <Button type="primary" onClick={handleSave} disabled={userUpdateInProgress}
                                    className="save-button">
                                {t('Save')}
                            </Button>
                            <Button type="default" onClick={handleEditToggle} className="cancel-button">
                                {t('Cancel')}
                            </Button>
                        </>
                    ) : (
                        <Button type="primary" onClick={handleEditToggle} disabled={userUpdateInProgress}
                                className="edit-button">
                            {t('Edit')}
                        </Button>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default UserProfilePage;
