import {UserRole} from "../../entity/enums/UserRole";


export const adminRoles: UserRole[] = [
    UserRole.ROLE_OPERATOR,
    UserRole.ROLE_OPERATOR_SUPERVISOR,
    UserRole.ROLE_ADMIN
];

export const generalRoles: UserRole[] = [
    ...adminRoles,
    UserRole.ROLE_CLIENT
];
