import React from 'react';
//config
import './config/locale/i18nConfig';
//services
import RoutingService from './routing/RoutingService';
//components
import BaseLayout from "./layout/BaseLayout";
//styles
import './App.css';


const App: React.FC = () => {
    return <BaseLayout>{RoutingService.getRoutes()}</BaseLayout>;
}

export default App;
