import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setActiveRequestAC} from "../../../../redux/action/request";
import {AppDispatch} from "../../../../redux/type/AppDispatch";

import {RequestService} from "../../../../util/RequestService";


const useFetchDetailedRequest = (): string | undefined => {
    const {requestId} = useParams<{ requestId: string }>();
    const dispatch:AppDispatch = useDispatch();

    useEffect((): void => {
        if (requestId) {
            RequestService.getRequestById(requestId);
        } else {
            dispatch(setActiveRequestAC());
        }
    }, [requestId, dispatch]);

    return requestId;
};

export default useFetchDetailedRequest;