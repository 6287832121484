export enum API {
    BASE_URL = 'https://api.iauto.md',
    VERSION = '/v1',
    ERROR = 'error',
}

export enum API_MOCK {
    REQUEST_SERVICE_URL = 'http://localhost:8082/api',
    WEBSOCKET_BASE_URL = 'ws://localhost:8082/requests_chat/',
    USER_SERVICE_URL = 'http://localhost:8083/api',
}

export enum API_QA {
    REQUEST_SERVICE_URL = 'https://req.iauto.md/api',
    WEBSOCKET_BASE_URL = 'wss://req.iauto.md/requests_chat/',
    USER_SERVICE_URL = 'https://usr.iauto.md/api',
}

export enum HTTP_STATUS_CODE {
    OK = 200,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNSUPPORTED_MEDIA_TYPE = 415,
    I_M_TEAPOT = 418,
    RETRY = 449,
    AUTH_REQUIRED = 511,
}
