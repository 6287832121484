export const validatePrice = (value: any) => {
    const numberPattern = /^[0-9]*[.,]?[0-9]*$/;
    const parsedValue = parseFloat(value.replace(',', '.'));
    if (!numberPattern.test(value) || parsedValue < 0 || parsedValue > 1000000) {
        return Promise.reject(new Error('Цена должна быть числом от 0 до 1,000,000'));
    }
    return Promise.resolve();
};

export const validateQuantity = (value: any) => {
    const numberPattern = /^[0-9]+$/;
    const parsedValue = parseInt(value, 10);
    if (!numberPattern.test(value) || parsedValue < 1 || parsedValue > 1000000) {
        return Promise.reject(new Error('Количество должно быть целым числом от 1 до 1,000,000'));
    }
    return Promise.resolve();
};