import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Col } from 'antd';
import Meta from 'antd/es/card/Meta';

import '../main.css';

export interface CardData {
    type: 'basic' | 'large';
    title: string;
    description: string;
    image: string;
    to: string;
}

const MenuCard: FC<CardData> = ({ type, title, description, image, to }) => {
    const colSizeMobile = 24;
    const colSizeBig = type === 'large' ? 16 : 8;

    return (
        <Col
            xs={colSizeMobile}
            sm={colSizeBig}
            md={colSizeBig}
            lg={colSizeBig}
            xl={colSizeBig}
            className='iauto-card-col'>
            {type === 'large' ? (
                <>
                    <a href={to} target='_blank' rel='noopener noreferrer'>
                        <Card hoverable className='iauto-card-large'>
                            <Meta
                                avatar={<Avatar src={image} alt={title} />}
                                title={title}
                                description={description}
                            />
                        </Card>
                    </a>
                </>
            ) : (
                <Link to={to}>
                    <Card
                        hoverable
                        cover={<img alt={title} src={image} />}
                        className='iauto-card-basic'>
                        <Meta title={title} description={description} />
                    </Card>
                </Link>
            )}
        </Col>
    );
};

export default MenuCard;
