import {CarsActions} from '../action/car';
import {PartsActions} from '../action/parts';
import {CarService} from '../../util/CarService';


const initialState = {
    cars: [],
    vinDecodeInProgress: false
}

const car = (state = initialState, action: any): any => {
    switch (action.type) {
        case CarsActions.GET_CAR_ON_DECODE: {
            return {
                ...state,
                cars: action.payload
            }
        }
        case CarsActions.DECODE_CAR_PHOTO: {
            return {
                ...state,
                cars: action.payload
            }
        }
        case PartsActions.UPDATE_CAR_PARTS: {
            return {
                ...state,
                cars: CarService.updatePartsOnCar(state.cars, action.payload.parts, action.payload.autoHash)
            }
        }
        case CarsActions.VIN_DECODE_IN_PROGRESS: {
            return {
                ...state,
                vinDecodeInProgress: action.payload
            }
        }
        case CarsActions.GET_CAR_MODEL_DETAILS: {
            return {
                ...state,
                carModelDetails: action.payload
            }
        }
        default:
            return state;
    }
}

export default car;