import { CardData } from './MenuCard';

export const BASIC_CARD_DATA: CardData[] = [
    {
        type: 'basic',
        title: 'iAuto - Garage',
        description: '',
            // 'Аудит вашего автомобиля. В гараже вы сможете фиксировать все ваши затраты на автомобиль, вести журнал тех обслуживания, выполнять поиск запчастей быстрее и многое другое',
        image: '/img/main-card-garage.png',
        to: '/under_construct',
    },
    {
        type: 'basic',
        title: 'iAuto - поиск',
         description: '',
        //     'Поиск VIN-коду вашего автомобиля. Наши операторы подберут запчасти, расходники под технику любых марок и назначения. Ваш заказ будет доставлен в кратчайшие сроки',
        image: '/img/main-card-search.png',
        to: '/requests',
    },
];

export const LARGE_CARD_DATA: CardData[] = [
    {
        type: 'large',
        title: 'iAuto - пaртнер',
        description:
            'На платформе наших партнеров cautpiese.md вы можете самостоятельно найти необходимые детали у различных поставщиков Молдовы',
        image: '/img/cautpiese-logo.webp',
        to: 'https://cautpiese.md/',
    },
];
