import {store} from "../redux/store";
//types
import {
    createRequestAC, getRequestByIdAC,
    getNotStartedRequestsAC,
    getRequestsAC,
    setActiveRequestAC, setActiveRequestInPreviewModeAC,
    updateRequestAC, updateRequestOnChatMessageReceiveAC
} from "../redux/action/request";

import {ChatRequest} from "../entity/type/ChatRequest";
import {RequestStatus} from "../entity/enums/RequestStatus";
import {User} from "../entity/type/User";

// const appState = store.getState();

export class RequestService {
    static async getRequestById(requestId: string): Promise<void> {
        // @ts-ignore
        await store.dispatch(getRequestByIdAC(requestId));
    }

    static async getRequests(requestParams: object): Promise<void> {
        // @ts-ignore
        await store.dispatch(getRequestsAC(requestParams));
    }

    static async getNotStartedRequests(): Promise<void> {
        // @ts-ignore
        await store.dispatch(getNotStartedRequestsAC({status: RequestStatus.CREATED}));
    }

    static updateRequestOnChatMessageReceive(message: any): void {
        // @ts-ignore
        store.dispatch(updateRequestOnChatMessageReceiveAC(message));
    }

    static async createRequest(userId: string, setActive: boolean): Promise<void> {
        // @ts-ignore
        store.dispatch(createRequestAC(userId, setActive));
    }

    static updateRequest(requestId: string): void {
        // @ts-ignore
        store.dispatch(getRequestByIdAC(requestId))
    };

    static async setActiveRequest(request: ChatRequest | {}): Promise<void> {
        // @ts-ignore
        await store.dispatch(setActiveRequestAC(request));
    }

    static async updateRequestAndRefreshActiveRequests(updateRequestParams: any, getRequestParams: any): Promise<void> {
        // @ts-ignore
        await store.dispatch(updateRequestAC(updateRequestParams));
        // @ts-ignore
        await store.dispatch(getRequestsAC(getRequestParams));
    }

    static async createRequestAndRefreshActiveRequests(userId: string, getRequestParams: any): Promise<void> {
        // @ts-ignore
        await store.dispatch(createRequestAC(userId, true));
        // @ts-ignore
        await store.dispatch(getRequestsAC(getRequestParams));
    }

    static async reserveAvailableRequestAndRefreshRequestsLists(userData: User, requestId: string): Promise<void> {
        const requestParams = {
            operatorId: userData.id,
        }
        const updateParams = {
            requestId: requestId,
            operatorId: userData.id,
            status: RequestStatus.IN_PROGRESS
        }

        // @ts-ignore
        await store.dispatch(updateRequestAC(updateParams));
        // @ts-ignore
        store.dispatch(setActiveRequestInPreviewModeAC(false));
        // @ts-ignore
        store.dispatch(getRequestsAC(requestParams));
        // @ts-ignore
        store.dispatch(getNotStartedRequestsAC({status: RequestStatus.CREATED}));
    }

    static async previewAvailableRequest(requestId: string): Promise<void> {
        // @ts-ignore
        await store.dispatch(getRequestByIdAC(requestId));
        // @ts-ignore
        store.dispatch(setActiveRequestInPreviewModeAC());
    }
}