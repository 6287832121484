import {useSelector} from 'react-redux';
import {UserRole} from "../../entity/enums/UserRole";
import {AuthenticatedUser} from "../../entity/type/User";
import {userDataSelector} from "../../redux/selector/userSelector";


/**
 * Hook which is used in case when we divide client logic from other roles logic
 * @param roles
 */
const useHasPermission = (roles: UserRole[]): boolean => {
    const userData: AuthenticatedUser = useSelector(userDataSelector);
    const userRole: string = userData?.user?.role;

    if (!userRole) {
        return false;
    }

    return roles.includes(userRole as UserRole);
};

export default useHasPermission;
