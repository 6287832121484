import {RootState} from "../redux/type/RootStore";
import {store} from "../redux/store";
import {AuthenticatedUser} from "../entity/type/User";


export const getAuthHeaders = (): object => {
    const state: RootState = store.getState();
    const userData: AuthenticatedUser = state.user.userData;
    return {
        'Content-Type': 'application/json',
        phone: userData?.auth?.phone,
        smsCode: userData?.auth?.smsCode,
        iAuto_token: userData?.auth?.token,
        'Authorization': userData?.auth?.imageAuthData?.tokenType + " " + userData?.auth?.imageAuthData?.accessToken
    };
}