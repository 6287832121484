import {ServiceParametersActions} from '../action/serviceParameters';


const initialState = {
    collapsedCars: [],
    showLoader: false,
    showPartsLoader: false,
    notificationModalVisible: false,
    notificationModalData: {},
    offerModalDisplayed: false,
    authCodeInputVisible: false
}

const serviceParameters = (state = initialState, action: any): any => {
    switch (action.type) {
        case ServiceParametersActions.GET_COLLAPSED_CARS: {
            return {
                ...state,
                collapsedCars: action.payload
            }
        }
        case ServiceParametersActions.COLLAPSE_CAR_PARTS: {
            return {
                ...state,
                collapsedCars: action.payload
            }
        }
        case ServiceParametersActions.HIDE_CAR_PARTS: {
            return {
                ...state,
                collapsedCars: action.payload
            }
        }
        case ServiceParametersActions.SHOW_LOADER: {
            return {
                ...state,
                showLoader: action.payload
            }
        }
        case ServiceParametersActions.SHOW_PARTS_LOADER: {
            return {
                ...state,
                showPartsLoader: action.payload
            }
        }
        case ServiceParametersActions.OPEN_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModalVisible: action.payload
            }
        }
        case ServiceParametersActions.CLOSE_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModalVisible: action.payload
            }
        }
        case ServiceParametersActions.SET_NOTIFICATION_MODAL_DATA: {
            return {
                ...state,
                notificationModalData: action.payload
            }
        }
        case ServiceParametersActions.DISPLAY_OFFER_MODAL: {
            return {
                ...state,
                offerModalDisplayed: action.payload
            }
        }
        case ServiceParametersActions.SET_AUTH_CODE_INPUT_VISIBLE: {
            return {
                ...state,
                authCodeInputVisible: action.payload
            }
        }
        default:
            return state;
    }
}

export default serviceParameters;