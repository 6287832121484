import {useTranslation} from "react-i18next";
import React, {FC} from "react";
import {Card, Col, Empty, Row,Image} from "antd";

import {Offer} from "../../../../../entity/type/Offer";
import {OfferPosition} from "../../../../../entity/type/OfferPosition";


type OfferCardsProps = {
    offerData: Offer;
};

const OfferCards: FC<OfferCardsProps> = ({ offerData }) => {
    const { t } = useTranslation();

    return (
        <>
            {offerData.offerPositions.map((position: OfferPosition, index: number) => (
                <Card key={index} className="offer-card">
                    <Row gutter={[8, 8]}>
                        {position.images && position.images.length > 0 ? (
                            <Col span={24}>
                                <Image
                                    width={100}
                                    src={position.images[0]?.image || ''}
                                    alt="Product Image"
                                    fallback="https://via.placeholder.com/80"
                                    style={{ objectFit: 'cover', maxHeight: 150 }}
                                />
                            </Col>
                        ) : (
                            <Col span={24}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <strong>{t('Продукта')}:</strong> {position.productName || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Описание продукта')}:</strong> {position.description || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Бренд')}:</strong> {position.brand || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Цена')}:</strong> {position.price || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Количество')}:</strong> {position.quantity || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Код')}:</strong> {position.productCode || t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Доставка')}:</strong>{' '}
                            {position.deliveryTime
                                ? `${position.deliveryTime[0]} - ${position.deliveryTime[1]}`
                                : t('N/A')}
                        </Col>
                        <Col span={24}>
                            <strong>{t('Заметки')}:</strong> {position.notes || t('N/A')}
                        </Col>
                    </Row>
                </Card>
            ))}
        </>
    );
};

export default OfferCards;