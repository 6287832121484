import React, {useState} from 'react';

import {Button, Upload} from 'antd';
import {CameraOutlined, SendOutlined} from '@ant-design/icons';
import {RcFile} from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";

import './chat_input.css';


type ChatMessageInputProps = {
    messageText: string;
    messageImages: any[];
    onMessageTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSendMessage: (e: any) => any;
    onImageUpload: (file: RcFile) => Promise<boolean>;
};

const ChatMessageInput: React.FC<ChatMessageInputProps> = ({
                                                               messageText,
                                                               onMessageTextChange,
                                                               onSendMessage,
                                                               onImageUpload,
                                                           }) => {
    const [isUploading, setIsUploading] = useState(false);

    const handleImageUpload = async (file: RcFile) => {
        setIsUploading(true);

        try {
            const uploadSuccessful = await onImageUpload(file);

            if (uploadSuccessful) {
                // Handle successful upload
            } else {
                // Handle upload failure
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            // Handle upload error
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className='iauto-request-chat-footer'>
            <TextArea
                className='iauto-request-chat-input'
                autoSize={{minRows: 1, maxRows: 1}}
                placeholder='Enter message...'
                value={messageText}
                disabled={isUploading}
                variant='borderless'
                onChange={onMessageTextChange}
                onPressEnter={onSendMessage}
            />
            <div className='iauto-request-chat-button-block'>
                {/*<Button*/}
                {/*    type={listening ? 'default' : 'text'}*/}
                {/*    size='large'*/}
                {/*    shape='circle'*/}
                {/*    icon={<AudioOutlined />}*/}
                {/*    disabled={isUploading}*/}
                {/*    onClick={listening ? onStopListening : onListen}*/}
                {/*/>*/}
                <Upload
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={handleImageUpload}
                    disabled={isUploading}
                    customRequest={() => {}}
                >
                    <Button
                        type='text'
                        size='large'
                        shape='circle'
                        disabled={isUploading}
                        icon={<CameraOutlined/>}
                    />
                </Upload>
                <Button
                    type='text'
                    size='large'
                    shape='circle'
                    disabled={isUploading || !messageText.trim()}
                    icon={<SendOutlined/>}
                    onClick={onSendMessage}
                />
            </div>
        </div>
    );
};

export default ChatMessageInput;
