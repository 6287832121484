import {Dispatch} from 'redux';
import axios, {AxiosResponse} from 'axios';
//type
import {API} from '../../api/type/Api';
//service
import LocalStorageService from '../../security/LocalStorageService';
import {ServiceParametersService} from "../../util/ServiceParametersService";


export enum PartsActions {
    GET_PARTS_FOR_CAR = 'GET_PARTS_FOR_CAR',
    UPDATE_CAR_PARTS = 'UPDATE_CAR_PARTS',
    UPDATE_CAR_PARTS_FOR_SEARCH_HISTORY = 'UPDATE_CAR_PARTS_FOR_SEARCH_HISTORY'
}

export const getPartsForCarAC = (autoHash: string = '') => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body = {autoHash: autoHash};

    // return axios.post(API.BASE_URL + API.VERSION + '/decode/parts', body, {headers})
    return axios.post(API.VERSION + '/decode/parts', body, {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: PartsActions.UPDATE_CAR_PARTS,
                payload: {
                    autoHash: autoHash,
                    parts: response?.data?.data
                }
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

export const getPartsForCarInSearchHistoryAC = (autoHash: string = '', historyId: number) => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body = {autoHash: autoHash};

    // return axios.post(API.BASE_URL + API.VERSION + '/decode/parts', body, {headers})
    return axios.post(API.VERSION + '/decode/parts', body, {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: PartsActions.UPDATE_CAR_PARTS_FOR_SEARCH_HISTORY,
                payload: {
                    autoHash: autoHash,
                    historyId: historyId,
                    parts: response?.data?.data
                }
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}