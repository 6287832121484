import React, {FC, useEffect} from 'react';
import {useSelector} from "react-redux";
import {activeRequestSelector, requestsDataSelector} from "../../../../../redux/selector/requestSelector";
import {userDataSelector} from "../../../../../redux/selector/userSelector";
import {useTranslation} from 'react-i18next';
//components & types
import {ChatRequest} from "../../../../../entity/type/ChatRequest";
import {AuthenticatedUser, User} from "../../../../../entity/type/User";
import {Button, Col,  Row} from "antd";
import LeftMenu from "../left_menu/LeftMenu";
import {SettingFilled} from "@ant-design/icons";
import Chat from "../../../../common/chat/Chat";
//services
import {RequestService} from "../../../../../util/RequestService";

import './client_content.css'
import {PaginatedResponse} from "../../../../../api/type/PaginatedResponse";


const ClientContent: FC = () => {
    const requestsData: PaginatedResponse<ChatRequest> = useSelector(requestsDataSelector);
    const activeRequestData: PaginatedResponse<ChatRequest> = useSelector(activeRequestSelector);
    const user: AuthenticatedUser = useSelector(userDataSelector);
    const userData: User = user?.user;
    const {t} = useTranslation();

    useEffect(() => {
        RequestService.getRequests({clientId: userData.id});
    }, []);

    const onCreateRequestButtonClick = async (): Promise<void> => {
        //TODO make collecting of request params in service
        await RequestService.createRequestAndRefreshActiveRequests(userData.id,
            {clientId: userData.id})
    }

    return (
        <Row>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                <LeftMenu requestsData={requestsData} userData={userData}/>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className='iauto-cabinet-request-container'>
                    <Button onClick={onCreateRequestButtonClick}
                            icon={<SettingFilled/>}
                            className='iauto-new-request-button'
                    >
                        {t('Find me a part')}
                    </Button>
                    {activeRequestData && <Chat/>}
                </div>
            </Col>
        </Row>
    );
};

export default ClientContent;