import {Dispatch} from 'redux';
import axios, {AxiosResponse} from 'axios';
import {REQUESTS_API_ENDPOINTS} from "../../api/apiEndpoints";
import {apiClient} from "../../api/apiClient";

import {isDevelopmentMode} from "../../util/EnvUtility";

import {API, API_MOCK, API_QA} from '../../api/type/Api';
import {ChatRequest} from '../../entity/type/ChatRequest';
import {PaginatedResponse} from "../../api/type/PaginatedResponse";

import {ServiceParametersService} from '../../util/ServiceParametersService';
import UserService from '../../service/UserService';


export enum RequestActions {
    GET_REQUESTS = 'GET_REQUESTS',
    GET_REQUESTS_LOADING = 'GET_REQUESTS_LOADING',
    GET_FAILURE = 'GET_FAILURE',

    GET_NOT_STARTED_REQUESTS = 'GET_NOT_STARTED_REQUESTS',
    GET_NOT_STARTED_REQUESTS_LOADING = 'GET_NOT_STARTED_REQUESTS_LOADING',
    GET_NOT_STARTED_REQUESTS_FAILURE = 'GET_NOT_STARTED_REQUESTS_FAILURE',

    GET_DETAILED_REQUEST = 'GET_DETAILED_REQUEST',
    GET_REQUEST = 'GET_REQUEST',
    CREATE_REQUEST = 'CREATE_REQUEST',
    UPDATE_REQUEST = 'UPDATE_REQUEST',
    SET_ACTIVE_REQUEST = 'SET_ACTIVE_REQUEST',
    SET_ACTIVE_REQUEST_IN_PREVIEW_MODE = 'SET_ACTIVE_REQUEST_IN_PREVIEW_MODE',
    UPDATE_REQUEST_ON_CHAT_MESSAGE_RECEIVE = 'UPDATE_REQUEST_ON_CHAT_MESSAGE_RECEIVE'
}

/**
 * @GET one request by id
 * @param requestId
 */
//TODO use new apiCLient
export const getRequestByIdAC = (requestId: string) => async (dispatch: Dispatch) => {
    // @ts-ignore
    const url: string = isDevelopmentMode() ?
        API_MOCK.REQUEST_SERVICE_URL + API.VERSION + '/requests/' + requestId
        :
        API_QA.REQUEST_SERVICE_URL + API.VERSION + '/requests/' + requestId;

    const headers: object = UserService.getAuthHeaders();
    const requestConfig: object = {
        headers: headers,
        params: {}
    };

    return new Promise((resolve, reject) => {
        axios
            .get(url, requestConfig)
            .then((response: AxiosResponse<any>) => {
                dispatch({
                    type: RequestActions.GET_DETAILED_REQUEST,
                    payload: response?.data
                });
                resolve(response?.data);
            })
            .catch(e => {
                ServiceParametersService.processDataForNotificationModal(e, 'Such request does not exist');
                reject(e);
            });
    });
};

/**
 * @GET list of requests on criteria
 * @param requestParams
 */
export const getRequestsAC = (requestParams: object) => async (dispatch: Dispatch) => {
    dispatch({
        type: RequestActions.GET_REQUESTS_LOADING,
        payload: true,
    });

    try {
        const response = await apiClient.get<PaginatedResponse<ChatRequest>>(
            REQUESTS_API_ENDPOINTS.getRequests(),
            {params: requestParams}
        );

        dispatch({
            type: RequestActions.GET_REQUESTS,
            payload: response,
        });
        dispatch({
            type: RequestActions.GET_REQUESTS_LOADING,
            payload: false,
        });

        return response;
    } catch (error) {
        dispatch({
            type: RequestActions.GET_REQUESTS_LOADING,
            payload: false,
        });
        dispatch({
            type: RequestActions.GET_FAILURE,
            payload: error,
        });

        ServiceParametersService.processDataForNotificationModal(error);
    } finally {
        dispatch({
            type: RequestActions.GET_REQUESTS_LOADING,
            payload: false,
        });
    }
};

/**
 * @GET requests which are not taken in work by operator
 * @param requestParams
 */
export const getNotStartedRequestsAC = (requestParams: object) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: RequestActions.GET_NOT_STARTED_REQUESTS_LOADING,
        payload: true,
    });

    try {
        const response = await apiClient.get<PaginatedResponse<ChatRequest>>(
            REQUESTS_API_ENDPOINTS.getRequests(),
            {params: requestParams}
        );

        dispatch({
            type: RequestActions.GET_NOT_STARTED_REQUESTS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: RequestActions.GET_NOT_STARTED_REQUESTS_FAILURE,
            payload: error,
        });

        ServiceParametersService.processDataForNotificationModal(error);
    } finally {
        dispatch({
            type: RequestActions.GET_NOT_STARTED_REQUESTS_LOADING,
            payload: false,
        });
    }
};

//TODO use new apiCLient and put comment
export const createRequestAC = (userId: string = '', setActive: boolean) => async (dispatch: Dispatch) => {
    const url: string = isDevelopmentMode() ?
        API_MOCK.REQUEST_SERVICE_URL + API.VERSION + '/requests'
        :
        API_QA.REQUEST_SERVICE_URL + API.VERSION + '/requests';

    const requestConfig: object = {
        headers: UserService.getAuthHeaders()
    };
    const body = {
        userId: userId
    };

    // return axios.post(API.BASE_URL + API.VERSION + '/decode/parts', body, {headers})
    return new Promise((resolve, reject): void => {
        axios.post(url, body, requestConfig)
            .then((response: AxiosResponse<any>): void => {
                dispatch({
                    type: RequestActions.CREATE_REQUEST,
                    payload: response?.data
                });
                if (setActive) {
                    dispatch({
                        type: RequestActions.SET_ACTIVE_REQUEST,
                        payload: response?.data
                    });
                }
                resolve(response?.data);
            })
            .catch(e => {
                ServiceParametersService.processDataForNotificationModal(e);
                reject(e);
            });
    });
};

//TODO use new apiCLient and put comment
export const updateRequestAC = (request: any = {}) => async (dispatch: Dispatch) => {
    const url: string = isDevelopmentMode() ?
        API_MOCK.REQUEST_SERVICE_URL + API.VERSION + '/requests/' + request.requestId
        :
        API_QA.REQUEST_SERVICE_URL + API.VERSION + '/requests/' + request.requestId

    let headers: object = UserService.getAuthHeaders();

    headers = {...headers, operatorId: request.operatorId}
    const requestConfig: object = {
        headers: headers
    };

    return new Promise((resolve, reject) => {
        axios
            .put(url, request, requestConfig)
            .then((response: AxiosResponse<any>) => {
                dispatch({
                    type: RequestActions.UPDATE_REQUEST,
                    payload: response?.data
                });
                resolve(response?.data);
            })
            .catch(e => {
                ServiceParametersService.processDataForNotificationModal(e);
                reject(e);
            });
    });
};

export const updateRequestOnChatMessageReceiveAC = (message: any) => (dispatch: Dispatch) => {
    dispatch({
        type: RequestActions.UPDATE_REQUEST_ON_CHAT_MESSAGE_RECEIVE,
        payload: message
    })
}

export const setActiveRequestAC = (request?: ChatRequest | undefined) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: RequestActions.SET_ACTIVE_REQUEST,
        payload: request
    });
}

export const setActiveRequestInPreviewModeAC = (isPreview: boolean = true) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: RequestActions.SET_ACTIVE_REQUEST_IN_PREVIEW_MODE,
        payload: isPreview
    })
}



