import React from 'react';
//component
import Header from '../component/common/header/Header';
import NotificationModal from '../component/common/notification_modal/NotificationModal';
import AppFooter from '../component/common/footer/Footer';

import './base_layout.css';


type BaseLayoutProps = {
    children: React.ReactNode;
};

const BaseLayout: React.FC<BaseLayoutProps> = (props: BaseLayoutProps) => {
    return (
        <div className='iauto-base-layout'>
            <Header />
            <section className='iauto-app-content'>{props.children}</section>
            <NotificationModal />
            <AppFooter />
        </div>
    );
};

export default BaseLayout;
