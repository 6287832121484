import React, {FC, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {userLang} from '../../../redux/selector/serviceParametersSelector';
import {useTranslation} from 'react-i18next';
import useIsAuthenticated from "../../../security/hook/useIsAuthenticated";
import LocalizationHelper from '../../../util/LocalizationHelper';
import UserService from "../../../service/UserService";

import {Menu} from 'antd';
import {LoginOutlined, LogoutOutlined, SearchOutlined, SettingFilled} from '@ant-design/icons';
import HeaderLogo from './HeaderLogo';

import {LangCodes} from '../../../config/locale/LangCodes';
import {PATHS} from "../../../routing/type/Path";

import './header.css';


const Header: FC = () => {
    const navigate = useNavigate();
    const isAuthenticated: boolean = useIsAuthenticated();
    const userLanguage: string = useSelector(userLang);
    const {i18n, t} = useTranslation();

    const changeLang = useCallback((langCode: string) => {
        i18n.changeLanguage(langCode.toLowerCase());
        LocalizationHelper.changeUserLang(langCode);
    }, [i18n]);

    const userLogOut = useCallback(async () => {
        await UserService.logOutUser();
        navigate(PATHS.ROOT_PATH);
    }, [navigate]);

    const getAuthItem = useCallback(() => {
        if (isAuthenticated) {
            return {
                label: t('Logout'),
                key: 'auth_out',
                icon: <LogoutOutlined/>,
                onClick: userLogOut
            };
        }

        return {
            label: t('Login'),
            key: 'auth',
            icon: <LoginOutlined/>,
            onClick: () => navigate(PATHS.AUTH_PATH)
        };
    }, [isAuthenticated, navigate, t, userLogOut]);

    const menuItems = useMemo(() => [
        {
            key: 'logo',
            icon: <HeaderLogo/>,
            className: 'iauto-header-logo',
            onClick: () => navigate(PATHS.ROOT_PATH),
        },
        {
            label: t('Search'),
            key: 'search',
            style: {marginLeft: 'auto'},
            icon: <SearchOutlined/>,
            onClick: () => navigate(PATHS.PART_SEARCH)
        },
        {
            label: t('Profile'),
            key: 'profile',
            icon: <SettingFilled/>,
            onClick: () => navigate(PATHS.MY_PROFILE)
        },
        {
            label: userLanguage.toUpperCase(),
            key: 'LangSubMenu',
            children: [
                {
                    label: LangCodes.RO,
                    key: LangCodes.RO,
                    onClick: () => changeLang(LangCodes.RO)
                },
                {
                    label: LangCodes.RU,
                    key: LangCodes.RU,
                    onClick: () => changeLang(LangCodes.RU)
                },
                {
                    label: LangCodes.EN,
                    key: LangCodes.EN,
                    onClick: () => changeLang(LangCodes.EN)
                }
            ]
        },
        getAuthItem()
    ], [navigate, t, userLanguage, changeLang, getAuthItem]);

    return (
        <header>
            <Menu
                mode='horizontal'
                items={menuItems}
                selectable={false}
                style={{width: '100%', backgroundColor: '#ffffff'}}
            />
        </header>
    );
};

export default React.memo(Header);