import {FILETYPES} from "../config/constant/Filetype";

/**
 * Service util to validate input strings
 */
class InputValidator {
    //TODO move these fields to enum ValidationConstraints
    static WIN_MAX_SIZE: number = 17;
    static EMPTY: number = 0;
    //TODO move these messages to enum structure 'ErrorMessages'
    static LENGTH_ERROR_MESSAGE = 'Win code length must be more than 0 symbols and less than 17';
    static UPLOAD_FILE_FORMAT_ERROR_MESSAGE = 'Wrong file. Please use files of formats: jpg/jpeg/png';

    static isValidLength = (str: string = ''): boolean => {
        return str.length <= this.WIN_MAX_SIZE;
    }

    static isNotEmpty = (str: string = ''): boolean => {
        return str.length !== this.EMPTY;
    }

    /**
     * Utility method to check uploaded file type. Allowed file is jpeg/jpg/png images
     * @param file
     */
    static uploadedFileFormatIsValid = (file: File): boolean => {
        const filetype: string = file.type;
        return FILETYPES.includes(filetype);
    }

    static uploadedFileSizeIsValid = (file: File): boolean => {
        return file.size / 1024 / 1024 < 2;
    }
}

export default InputValidator;