import {combineReducers} from 'redux';
import scanner from './scanner';
import settings from './settings';
import user from './user';
import parts from './parts';
import car from './car';
import serviceParameters from './serviceParameters';
import cachedServiceParameters from "./cachedServiceParameters";
import searchHistory from './searchHistory';
import request from './request';
import offer from './offer';
import images from './images';


/**
 * Root reducer which contains application state
 */
const reducer = combineReducers({
    scanner: scanner,
    settings: settings,
    user: user,
    parts: parts,
    car: car,
    request: request,
    offer: offer,
    images: images,
    serviceParameters: serviceParameters,
    cachedServiceParameters: cachedServiceParameters,
    searchHistory: searchHistory
});

export default reducer;