import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {userDataSelector} from "../../../../../../redux/selector/userSelector";
import {activeRequestSelector, notStartedRequestsSelector} from '../../../../../../redux/selector/requestSelector';
import {useTranslation} from 'react-i18next';
//entity
import {AuthenticatedUser, User} from '../../../../../../entity/type/User';
import {ChatRequest} from '../../../../../../entity/type/ChatRequest';
//component
import { List} from 'antd';
import AvailableRequestsItem from './AvailableRequestItem';
//service
import {RequestService} from '../../../../../../util/RequestService';

import './available-requests.css';
import {DetailedChatRequest} from "../../../../../../entity/type/DetailedChatRequest";
import {useNavigate} from "react-router-dom";
import {PATHS} from "../../../../../../routing/type/Path";
import {scrollToTop} from "../../../../../../util/WindowUtilities";
import {PaginatedResponse} from "../../../../../../api/type/PaginatedResponse";


export interface DataType {
    firstName: string | undefined;
    lastName:string | undefined;
    phone: string;
    createdAt: string;
    requestId: string,
    name?: '';
    description?: string
    mail?: string;
}

const AvailableRequests: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user: AuthenticatedUser = useSelector(userDataSelector);
    const userData: User = user?.user;
    const activeRequest: DetailedChatRequest = useSelector(activeRequestSelector);
    const requestsData: PaginatedResponse<ChatRequest> = useSelector(notStartedRequestsSelector);

    const dataSource: DataType[] = requestsData?.items?.map((request: ChatRequest) => ({
        firstName: request?.client?.firstName,
        lastName: request?.client?.lastName,
        phone: request?.client?.phoneNumber,
        requestId: request.id,
        mail: request?.client?.mail,
        description: request?.description,
        createdAt: request?.createdAt,
    }));

    const onRequestPreviewButtonClick = async (requestId: string): Promise<void> => {
        try {
            await RequestService.previewAvailableRequest(requestId);
            scrollToTop();
        } catch (error) {
            console.error('Failed to open request preview:', error);
        }
    };

    const onRequestReserveButtonClick = async (requestId: string): Promise<void> => {
        try {
            await RequestService.reserveAvailableRequestAndRefreshRequestsLists(userData, requestId);
            scrollToTop();
            navigate(`${PATHS.PART_SEARCH}/${activeRequest.id}`);
        } catch (error) {
            console.error('Failed to reserve request:', error);
        }
    };

    return (
        <div className="iauto-avail-requests-wrapper">
            <h2 className="iauto-operator-offer-panel-header">
                {t('New Requests')}
            </h2>
            <List
                itemLayout="horizontal"
                dataSource={dataSource}
                bordered
                renderItem={(item: DataType) => (
                    <AvailableRequestsItem
                        listItemData={item}
                        onRequestPreviewButtonClick={onRequestPreviewButtonClick}
                        onRequestReserveButtonClick={onRequestReserveButtonClick}
                    />
                )}
            />
        </div>
    );
};

export default AvailableRequests;