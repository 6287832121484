import { useState } from 'react';
import { formatPhoneNumber, isValidPhoneNumber } from './PhoneValidationUtility';


export const usePhoneValidation = (defaultCountryCode: string) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);

    const handlePhoneNumberChange = (input: string, countryCode: string = defaultCountryCode) => {
        const formatted = formatPhoneNumber(input, countryCode);
        setPhoneNumber(formatted);
        setIsValid(isValidPhoneNumber(formatted, countryCode));
    };

    return {
        phoneNumber,
        isValid,
        handlePhoneNumberChange,
    };
};
