import React, {FC, memo, useMemo} from 'react';
import {useSelector} from "react-redux";
import {userDataSelector} from "../../../../redux/selector/userSelector";
import {getFormattedDate, getFormattedHoursAndMinutes, makeRandomKey} from "../../../../util/Utilities";
import useHasPermission from "../../../../security/hook/useHasPermission";

import {Image} from "antd";
import ChatMessageImg from "./ChatMessageImg";
import ChatMessageTxt from "./ChatMessageTxt";
import ChatMessageAvatar from "./avatar/ChatMessageAvatar";

import {Offer} from "../../../../entity/type/Offer";
import {MessageImage} from "../../../../entity/type/Message";
import {MessageType} from "../MessageType";
import {AuthenticatedUser, User} from "../../../../entity/type/User";
import {adminRoles} from "../../../../security/hook/roles";

import './message.css';
import ChatMessageOffer from "./ChatMessageOffer";


interface ChatMessageProps {
    type: MessageType;
    text?: string;
    img?: MessageImage[];
    offer?: Offer;
    dateReceived?: string;
    senderName?: string
}

const ChatMessage: FC<ChatMessageProps> = ({
                                               type,
                                               text,
                                               img,
                                               offer,
                                               dateReceived,
                                               senderName
                                           }) => {
    const hasAdminPermission = useHasPermission(adminRoles);
    const userData: AuthenticatedUser = useSelector(userDataSelector);
    const user: User = userData?.user;
    const userRole: string = user?.role;

    const messageImages = useMemo(() => img?.map((imageData: MessageImage) => (
        <ChatMessageImg key={makeRandomKey()} imageData={imageData}
                        text={hasAdminPermission && imageData.text ? imageData.text : ''}/>
    )), [img, hasAdminPermission]);

    const renderMessageContent = useMemo(() => {
        switch (type) {
            case MessageType.STEP:
                return <div className='iauto-message-step'>{text}</div>;
            case MessageType.FROM:
                return (
                    <div className='iauto-message-in'>
                        <ChatMessageAvatar
                            avatarClassName='iauto-operator-avatar'
                            username={senderName}
                            avatarType={MessageType.FROM}
                            userRole={userRole}
                        />
                        <div className='iauto-message-content-left'>
                            {!offer && <ChatMessageTxt text={text} messageType={MessageType.FROM}
                                                       dateReceived={getFormattedHoursAndMinutes(dateReceived)}/>}
                            {offer && <ChatMessageOffer offer={offer} messageType={MessageType.FROM}
                                                        dateReceived={getFormattedHoursAndMinutes(dateReceived)}/>}
                            {img && img.length > 0 && (
                                <div className='iauto-message-images'>
                                    <Image.PreviewGroup>
                                        {messageImages}
                                    </Image.PreviewGroup>
                                </div>
                            )}
                        </div>
                    </div>
                );
            case MessageType.TO:
                return (
                    <div className='iauto-message-out'>
                        <div className='iauto-message-content-right'>
                            {!offer && <ChatMessageTxt text={text} messageType={MessageType.TO}
                                                       dateReceived={getFormattedHoursAndMinutes(dateReceived)}/>}
                            {offer && <ChatMessageOffer offer={offer} messageType={MessageType.TO}
                                                        dateReceived={getFormattedHoursAndMinutes(dateReceived)}/>}
                            {img && img.length > 0 && (
                                <div className='iauto-message-images'>
                                    <Image.PreviewGroup>
                                        {messageImages}
                                    </Image.PreviewGroup>
                                    <div>{getFormattedDate(dateReceived)}</div>
                                </div>
                            )}
                        </div>
                        <ChatMessageAvatar
                            avatarClassName='iauto-client-avatar'
                            username={senderName}
                            avatarType={MessageType.TO}
                            userRole={userRole}
                        />
                    </div>
                );
            default:
                return null;
        }
    }, [type, text, img, offer, senderName, userRole, messageImages]);

    return <>{renderMessageContent}</>;
};

export default memo(ChatMessage);