import {ImageActions} from "../action/images";

export type ImagesInitialStateType = {};

const initialState: ImagesInitialStateType = {}


const images = (state: ImagesInitialStateType = initialState, action: any): ImagesInitialStateType => {
    switch (action.type) {
        case ImageActions.SAVE_MESSAGE_IMAGE:


        default:
            return state;
    }
}

export default images;