import Part from './Part';
import {carBrandLogoDefault} from '../config/constant/CarBrand';

class Car {
    private _markName: string;
    private _autoHash: string;
    private _displayName: string;
    private _yearOfRelease: string;
    private _fuel: string;
    private _logo: string;
    private _modelInfo: ModelInfo | null
    private _parts: Array<Part>;

    constructor(markName: string, autoHash: string, displayName: string, yearOfRelease: string, fuel: string, modelInfo: ModelInfo | null) {
        this._markName = markName;
        this._autoHash = autoHash;
        this._displayName = displayName;
        this._yearOfRelease = yearOfRelease;
        this._fuel = fuel;
        this._logo = carBrandLogoDefault.path;
        this._modelInfo = modelInfo;
        this._parts = [];
    }

    get markName(): string {
        return this._markName;
    }

    set markName(value: string) {
        this._markName = value;
    }

    get autoHash(): string {
        return this._autoHash;
    }

    set autoHash(value: string) {
        this._autoHash = value;
    }

    get displayName(): string {
        return this._displayName;
    }

    set displayName(value: string) {
        this._displayName = value;
    }

    get yearOfRelease(): string {
        return this._yearOfRelease;
    }

    set yearOfRelease(value: string) {
        this._yearOfRelease = value;
    }

    get fuel(): string {
        return this._fuel;
    }

    set fuel(value: string) {
        this._fuel = value;
    }


    get logo(): string {
        return this._logo;
    }

    set logo(value: string) {
        this._logo = value;
    }

    get modelInfo(): ModelInfo | null {
        return this._modelInfo;
    }

    set modelInfo(value: ModelInfo | null) {
        this._modelInfo = value;
    }

    get parts(): Array<Part> {
        return this._parts;
    }

    set parts(value: Array<Part>) {
        this._parts = value;
    }
}

class ModelInfo {
    private _engineCode: string;
    private _yearFrom: string;
    private _yearUntil: string;
    private _fuel: string;
    private _modelName: string;

    constructor(engineCode: string, yearFrom: string, yearUntil: string, fuel: string, modelName: string) {
        this._engineCode = engineCode;
        this._yearFrom = yearFrom;
        this._yearUntil = yearUntil;
        this._fuel = fuel;
        this._modelName = modelName;
    }

    get engineCode(): string {
        return this._engineCode;
    }

    set engineCode(value: string) {
        this._engineCode = value;
    }

    get yearFrom(): string {
        return this._yearFrom;
    }

    set yearFrom(value: string) {
        this._yearFrom = value;
    }

    get yearUntil(): string {
        return this._yearUntil;
    }

    set yearUntil(value: string) {
        this._yearUntil = value;
    }

    get fuel(): string {
        return this._fuel;
    }

    set fuel(value: string) {
        this._fuel = value;
    }

    get modelName(): string {
        return this._modelName;
    }

    set modelName(value: string) {
        this._modelName = value;
    }
}

export {
    Car,
    ModelInfo
};