import {SearchHistoryActions} from "../action/searchHistory";
import {SearchHistoryService} from "../../util/SearchHistoryService";
import {PartsActions} from "../action/parts";
import {CarService} from "../../util/CarService";


const initialState = {
    searchHistoryData: []
}

const searchHistory = (state = initialState, action: any): any => {
    switch (action.type) {
        case SearchHistoryActions.GET_SEARCH_HISTORY: {
            return {
                ...state,
                searchHistoryData: SearchHistoryService.mapSearchHistoryItems(action.payload)
            }
        }
        case SearchHistoryActions.GET_SEARCH_HISTORY_DETAILS: {
            return {
                ...state,
                searchHistoryData: SearchHistoryService.addDetailsToSearchHistoryItem(state.searchHistoryData,
                    action.payload)
            }
        }
        case PartsActions.UPDATE_CAR_PARTS_FOR_SEARCH_HISTORY: {
            return {
                ...state,
                searchHistoryData: CarService.updatePartsOnCarForSearchHistory(state.searchHistoryData, action.payload)
            }
        }
        case SearchHistoryActions.ADD_COMMENT_TO_SEARCH_HISTORY: {
            return {
                ...state,
                searchHistoryData: SearchHistoryService.addCommentToSearchHistoryItem(state.searchHistoryData, action.payload)
            }
        }
        case SearchHistoryActions.DELETE_SEARCH_HISTORY_ITEM: {
            return {
                ...state,
                searchHistoryData: SearchHistoryService.deleteHistoryItemFromList(state.searchHistoryData, action.payload)
            }
        }
        default:
            return state;
    }
}

export default searchHistory;