import { CountryCode } from 'libphonenumber-js';

const labelToCountryCodeMap: Record<string, CountryCode> = {
    Russia: 'RU',
    Romania: 'RO',
    Ukraine: 'UA',
    Germany: 'DE',
    France: 'FR',
    Moldova: 'MD',
};
export type CountryPhoneCode = {
    value: string;
    label: string;
};

export const countryPhoneCodes: CountryPhoneCode[] = [
    { value: '+7', label: 'Russia' },
    { value: '+40', label: 'Romania' },
    { value: '+380', label: 'Ukraine' },
    { value: '+49', label: 'Germany' },
    { value: '+33', label: 'France' },
    { value: '+373', label: 'Moldova' }
];

/**
 * Maps a calling code to the corresponding ISO Country Code.
 * @param callingCode - The calling code (e.g., '+373').
 * @returns The ISO Country Code (e.g., 'MD') or null if not found.
 */
export const getCountryCodeFromCallingCode = (callingCode: string): CountryCode | null => {
    const country = countryPhoneCodes.find((entry) => entry.value === callingCode);
    return country ? labelToCountryCodeMap[country.label] || null : null;
};