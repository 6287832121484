//service
import {ServiceParametersService} from '../../util/ServiceParametersService';
//types
import {ModalData} from '../../entity/ModalData';
import {Car} from "../../entity/Car";


export enum ServiceParametersActions {
    GET_COLLAPSED_CARS = 'GET_COLLAPSED_CARS',
    COLLAPSE_CAR_PARTS = 'COLLAPSE_CAR_PARTS',
    HIDE_CAR_PARTS = 'HIDE_CAR_PARTS',
    SHOW_LOADER = 'SHOW_LOADER',
    SHOW_PARTS_LOADER = 'SHOW_PARTS_LOADER',
    OPEN_NOTIFICATION_MODAL = 'OPEN_NOTIFICATION_MODAL',
    CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL',
    SET_NOTIFICATION_MODAL_DATA = 'SET_NOTIFICATION_MODAL_DATA',
    DISPLAY_OFFER_MODAL = 'DISPLAY_OFFER_MODAL',
    SET_AUTH_CODE_INPUT_VISIBLE = 'SET_AUTH_CODE_INPUT_VISIBLE',
}


export const setAuthCodeInputVisibleAC = (isVisible: boolean = false) => ({
    type: ServiceParametersActions.SET_AUTH_CODE_INPUT_VISIBLE,
    payload: isVisible
});

export const getCollapsedCarsAC = (cars: Array<Car> = []) => ({
    type: ServiceParametersActions.GET_COLLAPSED_CARS,
    payload: ServiceParametersService.getCollapsedCars(cars)
});

export const showLoaderAC = (isLoading: boolean = false) => ({
    type: ServiceParametersActions.SHOW_LOADER,
    payload: isLoading
});

export const showPartsLoaderAC = (isLoading: boolean = false) => ({
    type: ServiceParametersActions.SHOW_PARTS_LOADER,
    payload: isLoading
});

export const openNotificationModalAC = () => ({
    type: ServiceParametersActions.OPEN_NOTIFICATION_MODAL,
    payload: true
});

export const closeNotificationModalAC = () => ({
    type: ServiceParametersActions.CLOSE_NOTIFICATION_MODAL,
    payload: false
});

export const setNotificationModalDataAC = (data: ModalData) => ({
    type: ServiceParametersActions.SET_NOTIFICATION_MODAL_DATA,
    payload: data
});

export const displayOfferModalAC = (isLoading: boolean = false) => ({
    type: ServiceParametersActions.DISPLAY_OFFER_MODAL,
    payload: isLoading
});
