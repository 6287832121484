// useAuthCheck.ts
import {useSelector} from 'react-redux';
import {userDataSelector} from "../../redux/selector/userSelector";
import {AuthenticatedUser} from "../../entity/type/User";

const useIsAuthenticated = (): boolean => {
    const userData: AuthenticatedUser = useSelector(userDataSelector);
    const isAuthenticated: boolean = !!userData && !!userData.auth?.phone && !!userData.auth?.token;

    return isAuthenticated;
};

export default useIsAuthenticated;
