import {Dispatch} from "redux";
import axios, {AxiosResponse} from "axios";

import {Offer} from "../../entity/type/Offer";
import {OfferStatus} from "../../entity/enums/OfferStatus";
import {API, API_MOCK, API_QA} from "../../api/type/Api";

import {ServiceParametersService} from "../../util/ServiceParametersService";
import UserService from "../../service/UserService";
import {isDevelopmentMode} from "../../util/EnvUtility";


export enum OfferActions {
    GET_OFFER_FOR_REQUEST = 'GET_OFFER_FOR_REQUEST',
    GET_OFFERS = 'GET_OFFERS',
    CREATE_OFFER = 'CREATE_OFFER',
    UPDATE_OFFER = 'UPDATE_OFFER',
    UPDATE_OFFER_STATUS = 'UPDATE_OFFER_STATUS'
}

/**
 * Get list of all created offers in one request
 * @param requestId
 */
export const getOfferDetailedAC = (requestId: string): any => ({
    type: OfferActions.GET_OFFER_FOR_REQUEST,
    payload: requestId
});

/**
 * Get offer list by user id(can be operator either client role)
 * @param userId
 */
export const getOffersAC = (userId: string): any => ({
    type: OfferActions.GET_OFFERS,
    payload: userId
});

export const createOrUpdatedOfferAC = (updatedOffer: Offer): any => ({
    type: OfferActions.UPDATE_OFFER,
    payload: updatedOffer
});

//TODO use new apiCLient and put comment
export const createOfferAC = (offerData: Record<string, any> = {}) => async (dispatch: Dispatch): Promise<any> => {
    const url: string = isDevelopmentMode()
        ? `${API_MOCK.REQUEST_SERVICE_URL}${API.VERSION}/offers`
        : `${API_QA.REQUEST_SERVICE_URL}${API.VERSION}/offers`;

    const requestConfig = {
        headers: UserService.getAuthHeaders(),
    };

    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios.post(url, offerData, requestConfig);
        dispatch({
            type: OfferActions.CREATE_OFFER,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        ServiceParametersService.processDataForNotificationModal(error);
        throw error; // Propagate the error to the caller
    }
};

//TODO use new apiCLient and put comment
export const updateOfferStatusAC = (offerId: string, status: OfferStatus) => async (dispatch: Dispatch) => {
    // @ts-ignore
    const url: string = isDevelopmentMode() ?
        API_MOCK.REQUEST_SERVICE_URL + API.VERSION + '/offers/status/' + offerId
        :
        API_QA.REQUEST_SERVICE_URL + API.VERSION + '/offers/status/' + offerId;
    const requestConfig: object = {
        headers: UserService.getAuthHeaders()
    };
    const body: object = {
        status: status
    };

    return new Promise((resolve, reject) => {
        axios
            .put(url, body, requestConfig)
            .then((response: AxiosResponse<any>) => {
                dispatch({
                    type: OfferActions.UPDATE_OFFER_STATUS,
                    payload: response?.data
                });
                resolve(response?.data);
            })
            .catch(e => {
                ServiceParametersService.processDataForNotificationModal(e);
                reject(e);
            });
    });
};
