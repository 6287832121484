const codeMapping = {
    "+373": "0",  // Moldova
    "+40": "0",   // Romania
};

/**
 * Formats an international phone number to a local format based on provided mappings.
 *
 * @param phoneNumber - The original phone number in international format (e.g., +37377000015).
 * @param codeMapping - An object mapping international prefixes to local prefixes.
 * @returns The phone number formatted with the local prefix, or the original if no match is found.
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) {
        return '';
    }

    for (const [internationalPrefix, localPrefix] of Object.entries(codeMapping)) {
        if (phoneNumber.startsWith(internationalPrefix)) {
            return phoneNumber.replace(internationalPrefix, localPrefix);
        }
    }

    return phoneNumber;
};
