/**
 * util logger
 */
class Logger {
    static _generateDate = (): string => {
        const currentDate = new Date();
        return '[' + currentDate.getDay() + '/'
            + (currentDate.getMonth() + 1) + '/'
            + currentDate.getFullYear() + '-'
            + currentDate.getHours() + ':'
            + currentDate.getMinutes() + ':'
            + currentDate.getSeconds() + ']';
    }

    /**
     * Simple message log
     * @param message {string}
     */
    static info = (message = ''): void => {
        console.info(this._generateDate() + ':' + message);
    }

    /**
     * Errors logger
     * @param message {string}
     */
    static error = (message = ''): void => {
        console.error(this._generateDate() + ':' + message)
    }

    /**
     * State logger
     * @param data {Object}
     */
    static debug = (data = {}): void => {
        console.log(this._generateDate() + ':' + data)
    }
}

export default Logger;