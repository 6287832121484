import {store} from "../redux/store";
import {Offer, OfferUpdate} from "../entity/type/Offer";
import {createOfferAC, createOrUpdatedOfferAC, updateOfferStatusAC} from "../redux/action/offer";
import {OfferStatus} from "../entity/enums/OfferStatus";


export class OfferService {
    static async createOrUpdatedOffer(offerInProgress: Offer | object): Promise<void> {
        // @ts-ignore
        await store.dispatch(createOrUpdatedOfferAC(offerInProgress));
    }

    static async createOfferAndSendMessage(offer: Offer | OfferUpdate): Promise<void> {
        // @ts-ignore
        return await store.dispatch(createOfferAC(offer));
    }

    static async updateOfferStatus(offer: Offer | undefined, status: OfferStatus): Promise<void> {
        // @ts-ignore
        await store.dispatch(updateOfferStatusAC(offer?.id, status));
    }
}