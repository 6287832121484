import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en_translate from "./translate/en_translate.json";
import ro_translate from "./translate/ro_translate.json";
import ru_translate from "./translate/ru_translate.json";
import LocalizationHelper from "../../util/LocalizationHelper";


const resources = {
    ro: {translation: ro_translate},
    ru: {translation: ru_translate},
    en: {translation: en_translate}
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: LocalizationHelper.getUserLang().toLowerCase(),
        fallbackLng: LocalizationHelper.getUserLang().toLowerCase(),
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;