/**
 * Method generates random string which can be used as react-element key
 * @returns key{string}
 */
export const makeRandomKey = (): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 100; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
};

export const getFormattedDate = (date: string | undefined): string => {
    if (!date) {
        return '';
    }

    return new Date(date).toLocaleDateString('fr-CA');
};

export const getFormattedHoursAndMinutes = (rawDate: string | undefined): string => {
    if (!rawDate) {
        return '';
    }
    const date: Date = new Date(rawDate);
    const formattedHours: string = date.getUTCHours().toString().padStart(2, '0');
    const formattedMinutes: string = date.getUTCMinutes().toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
};

export const getCurrentYear = (): number => {
    return new Date().getFullYear();
};

export const getDeepCopyObject = (entity: any): any => {
    return JSON.parse(JSON.stringify(entity));
}
