import React, {FC} from 'react';
import useHasPermission from "../../../security/hook/useHasPermission";
import {adminRoles} from "../../../security/hook/roles";

import ClientContent from './component/client_content/ClientContent';
import OperatorContent from './component/operator_content/OperatorContent';


const UserCabinetPage: FC = () => {
    const userIsAdminRole: boolean = useHasPermission(adminRoles);
    const content: any = userIsAdminRole ? <OperatorContent/> : <ClientContent/>;

    return (
        <>
            {content}
        </>
    );
};

export default UserCabinetPage;