import {PartsActions} from '../action/parts';

const initialState = {
    carParts: []
}

const parts = (state = initialState, action: any): any => {
    switch (action.type) {
        case PartsActions.GET_PARTS_FOR_CAR: {
            return {
                ...state,
                carParts: action.payload
            }
        }
        default:
            return state;
    }
}

export default parts;