import React, {FC} from "react";
import {MessageType} from "../MessageType";


interface ChatMessageTxtProps {
    text: string | undefined;
    dateReceived?: string
    messageType: MessageType
}

const ChatMessageTxt: FC<ChatMessageTxtProps> = ({
                                                     text,
                                                     messageType,
                                                     dateReceived
                                                 }: ChatMessageTxtProps) => {
    return <>
        <div>
            {dateReceived}
        </div>
        {
            messageType === MessageType.TO ?
                <div className='iauto-message-out-text'>
                    {text}
                </div>
                :
                <div className='iauto-message-in-text'>
                    {text}
                </div>
        }
    </>
};

export default ChatMessageTxt;