import React, { FC } from 'react';

import { Offer } from '../../../../entity/type/Offer';
import { MessageType } from '../MessageType';
import OfferMessageContent from './offer/OfferMessageContent';


interface ChatMessageOfferProps {
    offer?: Offer;
    messageType: MessageType;
    dateReceived?: string;
}

const ChatMessageOffer: FC<ChatMessageOfferProps> = ({
    offer,
    messageType,
    dateReceived,
}: ChatMessageOfferProps) => {
    return (
        <>
            <div>{dateReceived}</div>
            {messageType === MessageType.TO ? (
                <div className='iauto-message-out-offer'>
                    {offer && <OfferMessageContent offerData={offer} />}
                </div>
            ) : (
                <div className='iauto-message-in-offer'>
                    {offer && <OfferMessageContent offerData={offer} />}
                </div>
            )}
        </>
    );
};

export default ChatMessageOffer;
