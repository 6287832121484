import axios, {AxiosResponse} from 'axios';
import {Dispatch} from 'redux';
//types
import {API} from '../../api/type/Api';
//services
import LocalStorageService from '../../security/LocalStorageService';
import {CarService} from '../../util/CarService';
import {ServiceParametersService} from "../../util/ServiceParametersService";


export enum ScannerActions {
    DECODE_CAR_PHOTO = 'DECODE_CAR_PHOTO',
    REQUEST_CAR_CHECK = 'REQUEST_CAR_CHECK',
    GET_CAR_DATA = 'GET_CAR_DATA',
    SET_IMAGE_DATA = 'SET_IMAGE_DATA'
}

export const decodeCarPhotoAC = (imageData: string) => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body = {
        image: imageData,
    }

    axios.post(API.BASE_URL + API.VERSION + '/decode', body, {headers})
        .then((response: AxiosResponse<any>) => {
            const decodedCarData: Array<any> = CarService.processDecodedCarData(response.data.data);
            dispatch({
                type: ScannerActions.DECODE_CAR_PHOTO,
                payload: decodedCarData
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

export const requestCarCheckAC = (isLoading = false): any => (dispatch: Dispatch) => {
    dispatch({
        type: ScannerActions.REQUEST_CAR_CHECK,
        payload: isLoading
    });
}

export const getCarDataAC = (data = {}): any => (dispatch: Dispatch) => {
    dispatch({
        type: ScannerActions.GET_CAR_DATA,
        payload: data
    })
}

export const setImageDataAC = (base64Image: string | ArrayBuffer) => ({
    type: ScannerActions.SET_IMAGE_DATA,
    payload: base64Image
});






