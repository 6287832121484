import {OfferActions} from "../action/offer";
import {Offer} from "../../entity/type/Offer";

export type OfferInitialStateType = {

};

const initialState: OfferInitialStateType = {
    activeRequestOffers: Array<Offer>,
    offerUnderConstruction: {}
}

const offer = (state = initialState, action: any): OfferInitialStateType => {
    switch (action.type) {
        case OfferActions.CREATE_OFFER: {
            return {
                ...state
            }
        }
        case OfferActions.GET_OFFER_FOR_REQUEST: {
            return {
                ...state,
                activeRequestOffers: action.payload
            }
        }
        case OfferActions.GET_OFFERS: {
            return {
                ...state,
                offers: action.payload
            }
        }
        case OfferActions.UPDATE_OFFER: {
            return {
                ...state,
                offerUnderConstruction: action.payload
            }
        }

        default:
            return state;
    }
}

export default offer;