import React, { FC } from 'react';
import Icon from '@ant-design/icons';


const HeaderLogo: FC = () => {
  return (
    <Icon
      component={() => <img src="/app_logo.png" alt="iAuto" width={30} />}
    />
  );
};

export default HeaderLogo;
