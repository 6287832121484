import {UserActions} from "../action/user";
import {AuthenticatedUser, User} from "../../entity/type/User";

export type UserInitialStateType = {
    userData: AuthenticatedUser | {},
    mockUserData: User | {},
    userUpdateInProgress: boolean,
    userAuthLoading: boolean,
    userLogoutLoading: boolean,
    userUpdateLoading: boolean
};

const initialState: UserInitialStateType = {
    userData: {},
    userUpdateInProgress: false,
    mockUserData: {},
    userAuthLoading: false,
    userLogoutLoading: false,
    userUpdateLoading: false

}

const user = (state = initialState, action: any): UserInitialStateType => {
    switch (action.type) {
        case UserActions.GET_MOCK_USER: {
            return {
                ...state,
                mockUserData: action.payload
            }
        }
        case UserActions.AUTH_USER: {
            return {
                ...state,
                userData: action.payload
            }
        }
        case UserActions.LOGOUT_USER: {
            return {
                ...state,
                userData: {}
            }
        }
        case UserActions.UPDATE_USER: {
            const userDataCopy = {...state.userData};
            userDataCopy.user = {...userDataCopy.user, ...action.payload};

            return {
                ...state,
                userData: userDataCopy
            }
        }
        case UserActions.SET_USER_UPDATE_IN_PROGRESS: {
            return {
                ...state,
                userUpdateInProgress: action.payload
            }
        }
        case UserActions.AUTH_USER_LOADING: {
            return {
                ...state,
                userAuthLoading: action.payload
            }
        }
        case UserActions.LOGOUT_USER_LOADING: {
            return {
                ...state,
                userLogoutLoading: action.payload
            }
        }
        case UserActions.UPDATE_USER_LOADING: {
            return {
                ...state,
                userUpdateLoading: action.payload
            }
        }
        default:
            return state;
    }
}

export default user;