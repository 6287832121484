import {Car} from "./Car";


class SearchHistory {
    private _id: number;
    private _date: string;
    private _vin: string;
    private _details: Array<Car> | null;
    private _status: number;
    private _modelName: string;
    private _comment: string;

    constructor(id: number, date: string, vin: string, details: Array<Car> | null, status: number, modelName: string, comment: string) {
        this._id = id;
        this._date = date;
        this._vin = vin;
        this._details = details;
        this._status = status;
        this._modelName = modelName;
        this._comment = comment;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get vin(): string {
        return this._vin;
    }

    set vin(value: string) {
        this._vin = value;
    }

    get details(): Array<Car> | null {
        return this._details;
    }

    set details(value: Array<Car> | null) {
        this._details = value;
    }

    get status(): number {
        return this._status;
    }

    set status(value: number) {
        this._status = value;
    }

    get modelName(): string {
        return this._modelName;
    }

    set modelName(value: string) {
        this._modelName = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }
}

export {
    SearchHistory
};