import axios, { AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { setAuthCodeInputVisibleAC } from './serviceParameters';
//types
import { API, API_MOCK, API_QA, HTTP_STATUS_CODE } from '../../api/type/Api';
//services
import UserService from '../../service/UserService';
import { ServiceParametersService } from '../../util/ServiceParametersService';
import LocalStorageService from '../../security/LocalStorageService';
import Logger from '../../util/Logger';
import { isDevelopmentMode } from '../../util/EnvUtility';

export enum UserActions {
    GET_MOCK_USER = 'GET_MOCK_USER',
    AUTH_USER = 'AUTH_USER',
    AUTH_USER_LOADING = 'AUTH_USER_LOADING',
    LOGOUT_USER = 'LOGOUT_USER',
    LOGOUT_USER_LOADING = 'LOGOUT_USER_LOADING',
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_USER_LOADING = 'UPDATE_USER_LOADING',
    SET_USER_UPDATE_IN_PROGRESS = 'SET_USER_UPDATE_IN_PROGRESS',
}

//TODO use new apiCLient and put comment
export const authUserAC =
    (userPhone: string = '', smsCode: string = '') =>
    async (dispatch: Dispatch) => {
        const url: string = isDevelopmentMode()
            ? `${API_MOCK.USER_SERVICE_URL}${API.VERSION}/auth`
            : `${API_QA.USER_SERVICE_URL}${API.VERSION}/auth`;

        const requestBody: Object = {
            phone: userPhone,
            smsCode: smsCode,
        };

        dispatch({
            type: UserActions.AUTH_USER_LOADING,
            payload: true,
        });

        try {
            const response: AxiosResponse<any> = await axios.post(
                url,
                requestBody,
                {}
            );

            if (response.status === HTTP_STATUS_CODE.AUTH_REQUIRED) {
                dispatch(setAuthCodeInputVisibleAC(true));
            }

            if (response.status === HTTP_STATUS_CODE.OK) {
                dispatch({
                    type: UserActions.AUTH_USER,
                    payload: response?.data,
                });
                dispatch(setAuthCodeInputVisibleAC(false));
            }

            dispatch({
                type: UserActions.AUTH_USER_LOADING,
                payload: false,
            });
        } catch (error) {
            dispatch({
                type: UserActions.AUTH_USER_LOADING,
                payload: false,
            });
            // @ts-ignore
            if (error?.response?.status === HTTP_STATUS_CODE.AUTH_REQUIRED) {
                dispatch(setAuthCodeInputVisibleAC(true));
            }
        }
    };

//TODO use new apiCLient and put comment
export const logOutUserAC = () => async (dispatch: Dispatch) => {
    const url: string = isDevelopmentMode()
        ? `${API_MOCK.USER_SERVICE_URL}${API.VERSION}/logout`
        : `${API_QA.USER_SERVICE_URL}${API.VERSION}/logout`;
    const headers = UserService.getAuthHeaders();
    // @ts-ignore
    const requestBody: any = {token: headers.iAuto_token, phone: headers.phone};

    dispatch({
        type: UserActions.LOGOUT_USER,
        payload: {},
    });
    dispatch({
        type: UserActions.LOGOUT_USER_LOADING,
        payload: true,
    });

    return new Promise((resolve, reject): void => {
        // @ts-ignore
        axios
            .post(url, requestBody, { headers })
            .then((response: AxiosResponse<any>): void => {
                dispatch(setAuthCodeInputVisibleAC(false));
                dispatch({
                    type: UserActions.LOGOUT_USER_LOADING,
                    payload: false,
                });
                resolve(response?.data);
            })
            .catch((e) => {
                dispatch({
                    type: UserActions.LOGOUT_USER_LOADING,
                    payload: false,
                });
                ServiceParametersService.processDataForNotificationModal(e);
                reject(e);
            });
    });
};

export const authUserOnUniqueIdAC = (): Promise<void> => {
    // return axios.get(API.BASE_URL + API.VERSION + '/users/new')
    return axios
        .get(API.VERSION + '/users/new')
        .then(async (response: AxiosResponse<any>) => {
            if (response.data.data.uniqid) {
                LocalStorageService.saveToLocalStore(
                    LocalStorageService.UNIQUE_ID,
                    response.data.data.uniqid
                ).then(() => {});
            } else Logger.error('Error :: no unique id receive in response');
        })
        .catch((e) => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
};

//TODO use new apiCLient and put comment
export const updateUserDataAC =
    (userId: string, userData: any) => async (dispatch: Dispatch) => {
        const url: string = isDevelopmentMode()
            ? `${API_MOCK.USER_SERVICE_URL}${API.VERSION}/users/${userId}`
            : `${API_QA.USER_SERVICE_URL}${API.VERSION}/users/${userId}`;

        const requestConfig: object = {
            headers: UserService.getAuthHeaders(),
        };
        const body: object = userData;

        dispatch({
            type: UserActions.UPDATE_USER_LOADING,
            payload: true,
        });
        return new Promise((resolve, reject) => {
            axios
                .put(url, body, requestConfig)
                .then((response: AxiosResponse<any>) => {
                    dispatch({
                        type: UserActions.UPDATE_USER,
                        payload: response?.data,
                    });
                    dispatch({
                        type: UserActions.UPDATE_USER_LOADING,
                        payload: false,
                    });
                    resolve(response?.data);
                })
                .catch((e) => {
                    dispatch({
                        type: UserActions.UPDATE_USER_LOADING,
                        payload: false,
                    });
                    ServiceParametersService.processDataForNotificationModal(e);
                    reject(e);
                });
        });
    };

export const setUserUpdateInProgressAC = (inProgress: boolean): AnyAction => ({
    type: UserActions.SET_USER_UPDATE_IN_PROGRESS,
    payload: inProgress,
});
