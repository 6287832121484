import { useCallback } from 'react';
import {ChatRequest} from "../../../../entity/type/ChatRequest";
import {RequestStatus} from "../../../../entity/enums/RequestStatus";


export const useIsRequestClosed = (activeRequest: ChatRequest | undefined) => {
    return useCallback((): boolean => {
        return activeRequest?.status === RequestStatus.CLOSED;
    }, [activeRequest]);
};
