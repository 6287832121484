import React, {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { displayOfferModalAC } from '../../../../redux/action/serviceParameters';
import useHasPermission from '../../../../security/hook/useHasPermission';
import { useTranslation } from 'react-i18next';
import { useIsRequestClosed } from '../../../page/cabinet/hook/useIsRequestClosed';

import { RequestService } from '../../../../util/RequestService';
import { User } from '../../../../entity/type/User';
import { OfferStatus } from '../../../../entity/enums/OfferStatus';
import { adminRoles } from '../../../../security/hook/roles';
import { ChatRequest } from '../../../../entity/type/ChatRequest';
import { Message } from '../../../../entity/type/Message';

import { Button, Input } from 'antd';
import {
    CheckOutlined,
    EditOutlined,
    FileAddOutlined,
} from '@ant-design/icons';

import './header.css';

type ChatHeaderProps = {
    activeRequest: ChatRequest;
    userData: User;
};

const ChatHeader: FC<ChatHeaderProps> = ({ activeRequest, userData }) => {
    const hasAdminPermission = useHasPermission(adminRoles);
    const requestIsClosed = useIsRequestClosed(activeRequest);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialTitle = hasAdminPermission
        ? activeRequest?.titleByOperator
        : activeRequest?.titleByClient;
    const [requestTitle, setRequestTitle] = useState<string | undefined>(
        initialTitle
    );
    const [editBlockVisible, setEditBlockVisible] = useState<boolean>(false);
    const requestInPreviewMode: boolean = activeRequest.isPreview
        ? activeRequest.isPreview
        : false;

    useEffect(() => {
        setRequestTitle(initialTitle);
    }, [initialTitle]);

    const displayOfferModal = useCallback(() => {
        dispatch(displayOfferModalAC(true));
    }, [dispatch]);

    const onRequestNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setRequestTitle(e.target.value);
        },
        []
    );

    const saveNewRequestName = useCallback(async () => {
        const updateRequestParams = hasAdminPermission
            ? {
                  requestId: activeRequest.id,
                  operatorId: userData.id,
                  titleByOperator: requestTitle,
              }
            : {
                  requestId: activeRequest.id,
                  clientId: userData.id,
                  titleByClient: requestTitle,
              };
        const requestParams = hasAdminPermission
            ? { operatorId: userData.id }
            : { clientId: userData.id };

        try {
            await RequestService.updateRequestAndRefreshActiveRequests(
                updateRequestParams,
                requestParams
            );
            setEditBlockVisible(false);
        } catch (error) {
            console.error('Error updating request title:', error);
            // TODO: handle exception appropriately
        }
    }, [activeRequest.id, hasAdminPermission, requestTitle, userData.id]);

    const createOfferButton = useMemo(() => {
        const requestHasAcceptedOffer: boolean | undefined =
            activeRequest?.messages?.items?.some(
                (message: Message) =>
                    message.offer?.status === OfferStatus.ACCEPTED_BY_CLIENT ||
                    message.offer?.status === OfferStatus.ACCEPTED_BY_OPERATOR
            );

        if (
            hasAdminPermission &&
            !requestHasAcceptedOffer &&
            !requestIsClosed() &&
            !requestInPreviewMode
        ) {
            return (
                <Button
                    className='iauto-create-offer-button'
                    size='middle'
                    icon={<FileAddOutlined />}
                    onClick={displayOfferModal}>
                    {t('Create offer')}
                </Button>
            );
        }

        return null;
    }, [
        hasAdminPermission,
        activeRequest?.messages,
        displayOfferModal,
        t,
        requestIsClosed,
        requestInPreviewMode,
    ]);

    return (
        <div className='iauto-request-chat-header'>
            {createOfferButton}
            {editBlockVisible ? (
                <>
                    <Input
                        type='text'
                        value={requestTitle}
                        onChange={onRequestNameChange}
                        style={{ width: 300 }}
                    />
                    <Button
                        shape='circle'
                        icon={<CheckOutlined />}
                        onClick={saveNewRequestName}
                    />
                </>
            ) : (
                <>
                    <div>{requestTitle}</div>
                    {!requestIsClosed() && !requestInPreviewMode && (
                        <Button
                            type='dashed'
                            shape='circle'
                            icon={<EditOutlined />}
                            onClick={() => setEditBlockVisible(true)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default memo(ChatHeader);
