import axios, {AxiosResponse} from 'axios';
//type
import {Car} from "../../entity/Car";
import {API} from '../../api/type/Api';
//redux
import {AnyAction, Dispatch} from 'redux';
import {ScannerActions} from './scanner';
//service
import LocalStorageService from '../../security/LocalStorageService';
import {CarService} from '../../util/CarService';
import {ServiceParametersService} from '../../util/ServiceParametersService';


export enum CarsActions {
    VIN_DECODE_IN_PROGRESS = 'VIN_DECODE_IN_PROGRESS',
    DECODE_CAR_PHOTO = 'DECODE_CAR_PHOTO',
    GET_CAR_ON_DECODE = 'GET_CAR_ON_DECODE',
    GET_CAR_MODEL_DETAILS = 'GET_CAR_MODEL_DETAILS'
}

/**
 * Method receives user vin-code from input and requests car models
 * @param vinCode
 */
export const getCarOnVinInputAC = (vinCode: string = '') => async (dispatch: Dispatch) => {
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body = {text: vinCode};

    dispatch({
        type: CarsActions.VIN_DECODE_IN_PROGRESS,
        payload: true
    });

    // return axios.post(API.BASE_URL + API.VERSION + '/decode/text', body, {headers})
    return axios.post(API.VERSION + '/decode/text', body, {headers})
        .then((response: AxiosResponse<any>) => {
            const cars: Array<Car> = CarService.processDecodedCarData(response?.data);
            dispatch({
                type: CarsActions.VIN_DECODE_IN_PROGRESS,
                payload: false
            });

            return dispatch({
                type: CarsActions.GET_CAR_ON_DECODE,
                payload: cars
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

/**
 * Method receives user car doc photo encoded in base64 and requests car models
 * @param base64Photo
 * @param repeat is used when car doc photo was not decoded to give user possibility to retry decode
 */
export const decodeCarPhotoAC = (base64Photo: string | ArrayBuffer, repeat: boolean = false) => async (dispatch: Dispatch) => {
    const url: string = repeat ? '/decode/repeat' : '/decode';
    const headers = await LocalStorageService.getHeadersForUnregisteredUser();
    const body = {
        image: base64Photo,
    }

    dispatch({
        type: CarsActions.VIN_DECODE_IN_PROGRESS,
        payload: true
    });
    // return axios.post(API.BASE_URL + API.VERSION + url, body, {headers})
    return axios.post(API.VERSION + url, body, {headers})
        .then((response: AxiosResponse<any>) => {
            dispatch({
                type: CarsActions.VIN_DECODE_IN_PROGRESS,
                payload: true
            });
            dispatch({
                type: ScannerActions.DECODE_CAR_PHOTO,
                payload: CarService.processPhotoDecodedCarData(response?.data?.data)
            });
        })
        .catch(e => {
            ServiceParametersService.processDataForNotificationModal(e);
        });
}

export const getCarsAfterDecodeAC = (cars: Array<Car> = []): AnyAction => ({
    type: ScannerActions.DECODE_CAR_PHOTO,
    payload: cars
});

