import React from 'react';
import {useMemo} from 'react';
import {CheckCircleFilled, FileTextOutlined, ProfileFilled, SolutionOutlined, TruckFilled} from '@ant-design/icons';

import {ChatRequest} from "../../../../../../entity/type/ChatRequest";
import {PaginatedResponse} from "../../../../../../api/type/PaginatedResponse";
import {User} from "../../../../../../entity/type/User";
import {RequestStatus} from "../../../../../../entity/enums/RequestStatus";
import {UserRole} from "../../../../../../entity/enums/UserRole";
import {MenuItem} from "../type/MenuItem";


export const useRequestItems = (
    requestsData: PaginatedResponse<ChatRequest>,
    userData: User,
    handleItemClick: (request: ChatRequest, key: string) => void
) => {
    const getStatusIcon = (status: RequestStatus) => {
        const iconStyle = {fontSize: '20px'};

        switch (status) {
            case RequestStatus.CREATED:
            case RequestStatus.IN_PROGRESS:
                return <SolutionOutlined style={{color: '#387478', ...iconStyle}}/>;
            case RequestStatus.OFFER_CREATED:
                return <ProfileFilled style={{color: '#DA8359', ...iconStyle}}/>;
            case RequestStatus.IN_DELIVERY_BY_COURIER:
            case RequestStatus.IN_DELIVERY_BY_AGENT:
                return <TruckFilled style={{color: '#243642', ...iconStyle}}/>;
            case RequestStatus.CLOSED:
                return <CheckCircleFilled style={{color: '#88C273', ...iconStyle}}/>;
            default:
                return <FileTextOutlined/>;
        }
    };

    const getRequestItems = useMemo(() => {
        return (status: RequestStatus[]): MenuItem[] => {
            const filteredByStatus: ChatRequest[] = requestsData?.items
                ?.filter((request: ChatRequest) => status.includes(request.status));

            return filteredByStatus?.map((request) => {
                const key = request.id || Math.random().toString(36).substring(7);
                const itemTitle = userData?.role === UserRole.ROLE_CLIENT.toString()
                    ? request?.titleByClient
                    : request?.titleByOperator;

                return {
                    key,
                    icon: getStatusIcon(request.status),
                    label: itemTitle,
                    onClick: () => handleItemClick(request, key),
                };
            });
        };
    }, [requestsData, userData, handleItemClick]);

    return {getRequestItems};
};
