import {useCallback, useEffect, useState} from "react";
import {isDevelopmentMode} from "../util/EnvUtility";
import {API_MOCK, API_QA} from "../api/type/Api";


export const useChatWebSocket = (requestId: string, onMessageReceived: (message: any) => void): any => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const webSocketUrl: string = isDevelopmentMode() ? `${API_MOCK.WEBSOCKET_BASE_URL}${requestId}`
        : `${API_QA.WEBSOCKET_BASE_URL}${requestId}`;

    useEffect(() => {
        let ws: WebSocket | null = null;

        const connectWebSocket = () => {
            if (requestId) {
                ws = new WebSocket(webSocketUrl);
                setSocket(ws);

                ws.onopen = () => {
                    if (isDevelopmentMode()) {
                        console.log(`Connected to WebSocket for chatId: ${requestId}`);
                    }
                };

                ws.onmessage = (event) => {
                    const messageData = JSON.parse(event.data);
                    onMessageReceived(messageData);
                };

                ws.onerror = (error) => {
                    if (isDevelopmentMode()) {
                        console.error("WebSocket error: ", error);
                    }
                };
            }
        };

        const timer = isDevelopmentMode() ? setTimeout(connectWebSocket, 1000) : connectWebSocket();

        return (): void => {
            if (ws && ws.readyState !== WebSocket.CLOSED) {
                if (isDevelopmentMode()) {
                    console.log(`Disconnected from WebSocket for chatId: ${requestId}`);
                }
                ws.close();
            }
            // @ts-ignore
            clearTimeout(timer);
        };
    }, [requestId, onMessageReceived, webSocketUrl]);

    const sendMessage = useCallback(
        (messagePayload: any) => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                const messageJson = JSON.stringify(messagePayload);
                socket.send(messageJson);
            }
        },
        [socket]
    );

    return {sendMessage};
};