import {RootState} from '../type/RootStore';
import {ChatRequest} from "../../entity/type/ChatRequest";
import {PaginatedResponse} from "../../api/type/PaginatedResponse";


// @ts-ignore
interface RequestSelectorTypes extends RootState {
    requests: PaginatedResponse<ChatRequest>,
    notStartedRequests: PaginatedResponse<ChatRequest>,
    activeRequest: ChatRequest
}

export const requestsDataSelector = (state: RequestSelectorTypes): any => state.request.requests;
export const activeRequestSelector = (state: RequestSelectorTypes): any => state.request.activeRequest;
export const notStartedRequestsSelector = (state: RequestSelectorTypes): any => state.request.notStartedRequests;