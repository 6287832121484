import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentYear } from '../../../util/Utilities';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Layout, Button } from 'antd';

import './footer.css';

const { Footer } = Layout;

const AppFooter: FC = () => {
    const { t } = useTranslation();
    const rightsReservedText = `${t('Rights reserved')} © ${getCurrentYear()}`;

    const socialButtons = [
        {
            icon: <FacebookOutlined />,
            href: 'https://facebook.com',
            key: 'facebook',
        },
        {
            icon: <InstagramOutlined />,
            href: 'https://instagram.com',
            key: 'instagram',
        },
    ];

    return (
        <Footer className='iauto-app-footer'>
            <div className='iauto-footer-content'>
                <div className='iauto-rights-reserved'>
                    {rightsReservedText}
                </div>
                <div className='iauto-social-buttons'>
                    {socialButtons.map(({ icon, href, key }) => (
                        <Button
                            key={key}
                            type='link'
                            href={href}
                            target='_blank'
                            icon={icon}
                            className='iauto-social-button'
                        />
                    ))}
                </div>
            </div>
        </Footer>
    );
};

export default AppFooter;