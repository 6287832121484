import {store} from "../redux/store";
//services
import {setUserLangAC} from "../redux/action/cachedServiceParameters";
import {updateUserDataAC} from "../redux/action/user";
import {RootState} from "../redux/type/RootStore";
import {User} from "../entity/type/User";
import {LangCodes} from "../config/locale/LangCodes";
import LocalStorageService from "../security/LocalStorageService";


class LocalizationHelper {
    /**
     * Method to detect user's language and if user is not authenticated, browser default lang will be set
     * by default browser provides lang code in xx-XX format, that's why
     * we split it
     */
    static getUserLang = (): string => {
        // @ts-ignore
        const userDataJSON: User = LocalStorageService.getPersistedDataFromLocalStore()?.user;

        if (!userDataJSON) {
            return 'RO';
        }
        // @ts-ignore
        const userData: User = JSON.parse(userDataJSON)?.userData?.user;

        if (userData && userData.language) {
            return userData.language;
        }

        // @ts-ignore
        const browserLangCode: string = window.navigator['userLanguage'] || window.navigator.language;
        return browserLangCode.split('-')[0];
    }

    static changeUserLang = (langCode: string = LangCodes.RO.valueOf()): void => {
        const state: RootState = store.getState();
        const userData: User = {...state.user.userData?.user, language: langCode};

        // @ts-ignore
        store.dispatch(updateUserDataAC(userData.id, userData));
        store.dispatch(setUserLangAC(langCode));
    }
}

export default LocalizationHelper;