import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {notificationModalData, notificationModalVisible} from '../../../redux/selector/serviceParametersSelector';
import {Dispatch} from 'redux';
import {closeNotificationModalAC} from '../../../redux/action/serviceParameters';
//components
import {Button, Modal} from 'antd';
//entity
import { ModalData} from '../../../entity/ModalData';
import {HTTP_STATUS_CODE} from '../../../api/type/Api';


const NotificationModal: React.FC = () => {
    const modalVisible: boolean = useSelector(notificationModalVisible);
    const modalData: ModalData = useSelector(notificationModalData);
    const {t} = useTranslation();
    const dispatch: Dispatch = useDispatch();

    const handleOk = (): void => {
        modalData.onOk();
        dispatch(closeNotificationModalAC());
    };

    const handleCancel = (): void => {
        // @ts-ignore
        dispatch(modalData.onCancel());
    };

    const getModalContent = (): ReactElement => {
        let content: ReactElement;

        switch (modalData.code?.toString()) {
            case (HTTP_STATUS_CODE.I_M_TEAPOT.toString()):
                content = <>
                    <p>{t('Sorry, no information about car:')}</p>
                    <p>{modalData.error.detail[0].displayName}</p>
                    <p>{t('with engine:')}</p>
                    <p>{modalData.error.detail[0].engineCode}</p>
                </>
                break;
            default:
                content = <>
                    <p>{t('Something went wrong on our side.Please, try one more time, or enter vin-code manually')}</p>
                    <p>{t('If error repeat, please, contact administrator')}</p>
                </>;
        }

        return content;
    }

    const getModalFooter = (): Array<any> => {
        let footer: Array<any> = [];
        const closeButton = <Button key={'close'} onClick={handleCancel}>{t('Close')}</Button>;

        switch (modalData.code?.toString()) {
            case (HTTP_STATUS_CODE.NOT_FOUND.toString()):
            case (HTTP_STATUS_CODE.I_M_TEAPOT.toString()): {
                footer.push(closeButton);
                break;
            }
            case (HTTP_STATUS_CODE.RETRY.toString()): {
                footer.push(closeButton);
                footer.push(<Button key={'retry'} onClick={handleOk}>{t('Retry')}</Button>);
                break;
            }
            default:
                footer.push(closeButton);
        }

        return footer;
    }

    const modalContent: any = getModalContent();
    const footerButtons: Array<any> = getModalFooter();

    return (
        <Modal
            title={t('Attention')}
            open={modalVisible}
            closable={false}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={footerButtons}
        >
            {modalContent}
        </Modal>
    );
};

export default NotificationModal;