import axios from "axios";
import {isDevelopmentMode} from "./EnvUtility";

import UserService from "../service/UserService";
import {ServiceParametersService} from "./ServiceParametersService";

import {API, API_MOCK, API_QA} from "../api/type/Api";


export class ImagesService {
    static IMAGE_SERVICE_URL: string = isDevelopmentMode() ?
        `${API_MOCK.REQUEST_SERVICE_URL}${API.VERSION}/image_decoder`
        :
        `${API_QA.REQUEST_SERVICE_URL}${API.VERSION}/image_decoder`;

    static async saveMessageImage(imageData: any): Promise<void> {
        const requestConfig: object = {
            headers: UserService.getAuthHeaders()
        };
        const body: object = {
            from: imageData?.from,
            to: imageData?.to,
            request_id: imageData.request_id,
            image_base64: imageData.image_base64
        };

        try {
            await axios.post(this.IMAGE_SERVICE_URL, body, requestConfig);
        } catch (e) {
            ServiceParametersService.processDataForNotificationModal(e);
        }
    }
}