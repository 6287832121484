import { Action, applyMiddleware, compose, createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk, ThunkMiddleware } from 'redux-thunk';
// @ts-ignore
import logger from 'redux-logger';

import reducer from './reducer/reducer';
import { RootState } from './type/RootStore';

/**
 * Redux persis configuration
 */
const persistConfig: any = {
    key: 'root',
    storage,
    whitelist: ['user', 'cachedServiceParameters'],
};
// @ts-ignore
const persistedReducer = persistReducer<RootState>(persistConfig, reducer);

/**
 * Middleware config block
 */
let middleWares: Array<ThunkMiddleware<RootState, Action> | typeof logger> = [
    thunk,
];

if (process.env.NODE_ENV === 'development') {
    middleWares.push(logger);
}

/**
 * Configuration of redux store for application
 *
 */
const store: Store<RootState> = createStore(
    persistedReducer,
    compose(applyMiddleware(...middleWares))
);
const persistor = persistStore(store);

export { store, persistor };
