export const carBrandLogoDefault = {
    markName : 'DEFAULT',
    path: require('../../assets/images/car_logo/default_logo.png')
}

export const carBrandLogo = [
    //asia
    {
        markName : 'TOYOTA',
        path: require('../../assets/images/car_logo/toyota_logo.png')
    },
    {
        markName : 'NISSAN',
        path: require('../../assets/images/car_logo/nissan_logo.png')
    },
    {
        markName : 'HONDA',
        path: require('../../assets/images/car_logo/honda_logo.png')
    },
    {
        markName : 'SUBARU',
        path: require('../../assets/images/car_logo/subaru_logo.png')
    },
    {
        markName : 'KIA',
        path: require('../../assets/images/car_logo/kia_logo.png')
    },
    {
        markName : 'MITSUBISHI',
        path: require('../../assets/images/car_logo/mitsubishi_logo.png')
    },
    {
        markName : 'HYUNDAI',
        path: require('../../assets/images/car_logo/hyundai_logo.png')
    },
    {
        markName : 'ACURA',
        path: require('../../assets/images/car_logo/acura_logo.png')
    },
    {
        markName : 'DAEOWOO',
        path: require('../../assets/images/car_logo/daewoo_logo.png')
    },
    {
        markName : 'DATSUN',
        path: require('../../assets/images/car_logo/datsun_logo.png')
    },
    {
        markName : 'GEELY',
        path: require('../../assets/images/car_logo/geely_logo.png')
    },
    {
        markName : 'MAZDA',
        path: require('../../assets/images/car_logo/mazda_logo.png')
    },
    {
        markName : 'SUZUKI',
        path: require('../../assets/images/car_logo/suzuki_logo.png')
    },
    // german
    {
        markName : 'MERCEDES',
        path: require('../../assets/images/car_logo/mercedes_logo.png')
    },
    {
        markName : 'OPEL',
        path: require('../../assets/images/car_logo/opel_logo.png')
    },
    {
        markName : 'BMW',
        path: require('../../assets/images/car_logo/bmw_logo.png')
    },
    {
        markName : 'AUDI',
        path: require('../../assets/images/car_logo/audi_logo.png')
    },
    {
        markName : 'VOLKSWAGEN',
        path: require('../../assets/images/car_logo/volkswagen_logo.png')
    },
    {
        markName : 'PORSCHE',
        path: require('../../assets/images/car_logo/porsche_logo.png')
    },
    //italian
    {
        markName : 'FIAT',
        path: require('../../assets/images/car_logo/fiat_logo.png')
    },
    //french
    {
        markName : 'RENAULT',
        path: require('../../assets/images/car_logo/renault_logo.png')
    },
    {
        markName : 'CITROEN',
        path: require('../../assets/images/car_logo/citroen_logo.png')
    },
    {
        markName : 'PEUGEOT',
        path: require('../../assets/images/car_logo/peugeot_logo.png')
    },
    //british
    {
        markName : 'LAND_ROVER',
        path: require('../../assets/images/car_logo/land_rover_logo.png')
    },
    {
        markName : 'ROVER',
        path: require('../../assets/images/car_logo/rover_logo.png')
    },
    //other
    {
        markName : 'DACIA',
        path: require('../../assets/images/car_logo/dacia_logo.png')
    },
    {
        markName : 'SKODA',
        path: require('../../assets/images/car_logo/skoda_logo.png')
    },
    {
        markName : 'VOLVO',
        path: require('../../assets/images/car_logo/volvo_logo.png')
    },
    {
        markName : 'SAAB',
        path: require('../../assets/images/car_logo/saab_logo.png')
    },
    {
        markName : 'SEAT',
        path: require('../../assets/images/car_logo/seat_logo.png')
    },
    {
        markName : 'SATURN',
        path: require('../../assets/images/car_logo/saturn_logo.png')
    },
    //usa
    {
        markName : 'FORD',
        path: require('../../assets/images/car_logo/ford_logo.png')
    },
    {
        markName : 'CHEVROLET',
        path: require('../../assets/images/car_logo/chevrolet_logo.png')
    },
    {
        markName : 'CADILLAC',
        path: require('../../assets/images/car_logo/cadillac_logo.png')
    },
    {
        markName : 'CHRYSLER',
        path: require('../../assets/images/car_logo/chrysler_logo.png')
    },
    {
        markName : 'PONTIAC',
        path: require('../../assets/images/car_logo/pontiac_logo.png')
    },
];

export const carBrandNames: string[] = [
    "Toyota",
    "Honda",
    "Ford",
    "Chevrolet",
    "Volkswagen",
    "BMW",
    "Mercedes-Benz",
    "Audi",
    "Nissan",
    "Hyundai",
    "Kia",
    "Tesla",
    "Fiat",
    "Volvo",
    "Subaru",
    "Mazda",
    "Jeep",
    "Lexus",
    "Porsche",
    "Land Rover",
    "Jaguar",
    "Cadillac",
    "Mitsubishi",
    "Buick",
    "Chrysler",
    "Acura",
    "Infiniti",
    "GMC",
    "Lincoln",
    "Mini",
    "Ram",
    "Bentley",
    "Ferrari",
    "Maserati",
    "McLaren",
    "Rolls-Royce",
    "Bugatti",
    "Lamborghini",
    "Alfa Romeo",
    "Aston Martin",
    "Genesis",
    "Lotus",
    "Koenigsegg",
    "Pagani",
    "Spyker",
    "Maybach",
    "Morgan",
    "Smart",
    "Dacia",
    "Isuzu",
    "Lada",
    "Haval",
    "Chery",
    "Geely",
    "Suzuki",
    "Tata Motors",
    "Mahindra",
    "BYD",
    "Great Wall",
    "Maserati",
    "DS Automobiles",
    "SAIC Motor",
    "FAW",
    "Dongfeng Motor",
    "Changan Automobile",
    "GAC Group",
    "Brilliance Auto",
    "BYD",
    "Soueast",
    "Zotye",
    "Maxus",
    "WM Motor",
    "Haima Automobile",
    "Lifan Motors",
    "NIO",
    "Xpeng Motors",
    "WM Motor",
    "Zhi Dou",
    "Aiways",
    "Yema Auto",
    "Changhe Motors",
    "Zhidou Electric",
    "Qoros",
    "Jinbei",
    "Yutong",
    "JMC",
    "Zhongxing Automobile",
    "Hafei",
    "Wuling Motors",
    "Yudo",
    "Denza",
    "Jetour"
];



