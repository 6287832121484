import React, { FC, memo, useRef, useState } from 'react';
import { offerModalDisplayed } from '../../../../../../../redux/selector/serviceParametersSelector';
import { useSelector } from 'react-redux';
import { activeRequestSelector } from '../../../../../../../redux/selector/requestSelector';
import { userDataSelector } from '../../../../../../../redux/selector/userSelector';
import {useTranslation} from "react-i18next";

import {
    Button,
    Col,
    Divider,
    Drawer,
    Form,
    FormInstance,
    Row,
    Select,
    Space,
} from 'antd';
import OfferPositionItem from '../offer_form/offer_position/OfferPositionItem';
import { PlusSquareOutlined } from '@ant-design/icons';

import { ServiceParametersService } from '../../../../../../../util/ServiceParametersService';
import { OfferService } from '../../../../../../../util/OfferService';
import { getFormattedHoursAndMinutes } from '../../../../../../../util/Utilities';
import dayjs from 'dayjs';

import { OfferUpdate } from '../../../../../../../entity/type/Offer';
import { OfferPosition } from '../../../../../../../entity/type/OfferPosition';
import { ChatRequest } from '../../../../../../../entity/type/ChatRequest';
import { RequestService } from '../../../../../../../util/RequestService';
import { AuthenticatedUser, User } from '../../../../../../../entity/type/User';


const OfferMaker: FC = () => {
    const [offerFormIsVisible, setOfferFormIsDisplayed] =
        useState<boolean>(false);
    const [offerPositions, setOfferPositions] = useState<OfferPosition[]>([]);
    const activeRequest = useSelector(activeRequestSelector) as ChatRequest;
    const offerModalIsVisible = useSelector(offerModalDisplayed);
    const user: AuthenticatedUser = useSelector(userDataSelector);
    const userData: User = user?.user;
    const formInstanceRef = useRef<FormInstance>(null);
    const {t} = useTranslation();

    const addOfferPosition = (): void => {
        const newPosition: OfferPosition = {
            productName: '',
            brand: '',
            description: '',
            price: 0,
            quantity: 0,
            deliveryTime: [],
            notes: '',
            images: [],
        };
        setOfferPositions((prev) => [...prev, newPosition]);
        setOfferFormIsDisplayed(true);
    };

    const closeOfferMaster = () => {
        formInstanceRef.current?.resetFields();
        setOfferPositions([]);
        ServiceParametersService.displayOfferDrawer(false);
        handleOfferSelect('');
    };

    const createOffer = async (): Promise<void> => {
        try {
            const offer: OfferUpdate = {
                requestId: activeRequest.id,
                offerPositions,
            };
            await OfferService.createOfferAndSendMessage(offer);
            RequestService.getRequests({ operatorId: userData.id });
            closeOfferMaster();
        } catch (error) {
            console.error('Error creating offer:', error);
        }
    };

    const updateOfferPositions = (
        indexToUpdate: number,
        updatedData: OfferPosition
    ) => {
        setOfferPositions((prevPositions) => {
            const newPositions = [...prevPositions];
            newPositions[indexToUpdate] = updatedData;
            return newPositions;
        });
    };

    const deleteOfferPosition = (index: number) => {
        setOfferPositions((prev) => prev.filter((_, i) => i !== index));
    };

    const offerPositionsData = offerPositions.map((position, index) => (
        <OfferPositionItem
            key={index}
            offerNumber={index}
            positionData={position}
            updatePosition={(updatedData) =>
                updateOfferPositions(index, updatedData)
            }
            onDeletePosition={() => deleteOfferPosition(index)}
        />
    ));

    const getExistingOfferSelectOptions = () => {
        const existingOffers = activeRequest?.messages?.items
            ?.map((msg) => {
                if (msg.offer) {
                    return {
                        value: msg.offer.id,
                        label: `Оферта - ${getFormattedHoursAndMinutes(msg.offer.createdAt)}`,
                    };
                }
                return null;
            })
            .filter(
                (option): option is { value: string; label: string } =>
                    option !== null
            );

        return [
            { value: 'new', label: 'Создать новую оферту' },
            ...(existingOffers || []),
        ];
    };

    const handleOfferSelect = (value: string) => {
        if (value === 'new') {
            setOfferPositions([]);
            setOfferFormIsDisplayed(false);
            formInstanceRef.current?.resetFields();
            return;
        }

        const selectedOffer = activeRequest?.messages?.items?.find(
            (msg) => msg.offer?.id === value
        )?.offer;
        if (selectedOffer) {
            const updatedPositions = selectedOffer?.offerPositions?.map(
                (position) => {
                    if (position.images) {
                        position.images = [{ id: position?.images[0]?.id }];
                    } else {
                        position.images = [];
                    }

                    return position;
                }
            );
            setOfferPositions(updatedPositions || []);
            setOfferFormIsDisplayed(true);

            const fieldValues: Record<string, any> = {};
            selectedOffer.offerPositions.forEach((position, index) => {
                fieldValues[`productName[${index}]`] = position.productName;
                fieldValues[`brand[${index}]`] = position.brand;
                fieldValues[`description[${index}]`] = position.description;
                fieldValues[`price[${index}]`] = position.price;
                fieldValues[`quantity[${index}]`] = position.quantity;

                if (
                    position.deliveryTime &&
                    position.deliveryTime.length === 2
                ) {
                    const [startDate, endDate] = position.deliveryTime;

                    fieldValues[`deliveryTime[${index}]`] = [
                        dayjs(startDate).isValid()
                            ? dayjs(startDate).format('YYYY-MM-DD')
                            : null,
                        dayjs(endDate).isValid()
                            ? dayjs(endDate).format('YYYY-MM-DD')
                            : null,
                    ];
                } else {
                    fieldValues[`deliveryTime[${index}]`] = [null, null];
                }

                fieldValues[`productCode[${index}]`] = position.productCode;
                fieldValues[`supplier[${index}]`] = position.supplier;
                fieldValues[`notes[${index}]`] = position.notes;
                fieldValues[`imageUpload[${index}]`] = [];
            });

            formInstanceRef.current?.setFieldsValue(fieldValues);
        }
    };

    return (
        <Drawer
            // title='Мастер создания оферты'
            title={t('Offer wizard')}
            width={900}
            onClose={closeOfferMaster}
            open={offerModalIsVisible}
            getContainer={false}
            maskClosable={false}
            extra={
                <Space wrap>
                    <Button onClick={closeOfferMaster}>{t('Close')}</Button>
                    <Button
                        type='primary'
                        onClick={createOffer}
                        disabled={!offerPositions.length}>
                        {t('Create')}
                    </Button>
                </Space>
            }
            bodyStyle={{ padding: '16px', overflowY: 'auto' }}>
            <Row gutter={[16, 24]} align='middle' wrap>
                <Col xs={24} sm={12}>
                    <label
                        htmlFor='offer-select'
                        style={{
                            display: 'block',
                            marginBottom: '8px',
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}>
                        {t('Add product')}
                    </label>
                    <Button
                        icon={<PlusSquareOutlined />}
                        type='dashed'
                        onClick={addOfferPosition}
                        style={{ width: '100%' }}>
                        {t('Add a position')}
                    </Button>
                </Col>
                <Col xs={24} sm={12}>
                    <label
                        htmlFor='offer-select'
                        style={{
                            display: 'block',
                            marginBottom: '8px',
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}>
                        {t('Create based on')}
                    </label>
                    <Select
                        id='offer-select'
                        style={{ width: '100%' }}
                        onChange={handleOfferSelect}
                        options={getExistingOfferSelectOptions()}
                        placeholder={t('Select offer')}
                    />
                </Col>
            </Row>

            <Form layout='vertical' ref={formInstanceRef}>
                <Divider />
                {offerFormIsVisible && offerPositionsData}
            </Form>
        </Drawer>
    );
};

export default memo(OfferMaker);
