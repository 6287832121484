import React from 'react';
import {Route, Routes} from 'react-router-dom';
//components
import UserCabinetPage from '../component/page/cabinet/UserCabinetPage';
import LoginPage from '../component/page/auth_register/LoginPage';
import MainPage from '../component/page/main/MainPage';
import UnderConstructPage from '../component/page/under_construct/UnderConstructPage';
import ProtectedRoute from './ProtectedRoute';
import UserProfilePage from "../component/page/user_profile/UserProfilePage";
//services
import {makeRandomKey} from '../util/Utilities';
//types&const
import {PATHS} from './type/Path';


class RoutingService {
    static getRoutes = (): React.ReactNode => {
        return (
            <Routes>
                <Route
                    key={makeRandomKey()}
                    path={PATHS.ROOT_PATH}
                    element={<MainPage/>}
                />
                <Route
                    key={makeRandomKey()}
                    path={PATHS.AUTH_PATH}
                    element={<LoginPage/>}
                />
                <Route
                    key={makeRandomKey()}
                    path={PATHS.NOT_FOUND_PATH}
                    element={<UnderConstructPage/>}
                />
                <Route element={<ProtectedRoute/>}>
                    <Route
                        key={makeRandomKey()}
                        path={PATHS.PART_SEARCH}
                        element={<UserCabinetPage/>}
                    />
                    <Route
                        key={makeRandomKey()}
                        path={PATHS.PART_SEARCH_WITH_ID}
                        element={<UserCabinetPage />}
                    />
                </Route>
                <Route element={<ProtectedRoute/>}>
                    <Route
                        key={makeRandomKey()}
                        path={PATHS.MY_PROFILE}
                        element={<UserProfilePage/>}
                    />
                </Route>
            </Routes>
        );
    };
}

export default RoutingService;
