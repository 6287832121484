export class ApiError extends Error {
    public status: number;
    public message: string;
    public details?: any;

    constructor(status: number, message: string = "An error occurred", details?: any) {
        super(message);
        this.status = status;
        this.message = message;
        this.details = details;
    }
}
