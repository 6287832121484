import { isValidPhoneNumber } from 'libphonenumber-js';
import { getCountryCodeFromCallingCode } from './countryPhoneCodes';

export type ValidationRule = {
    required?: boolean;
    message?: string;
    pattern?: RegExp;
    max?: number;
    min?: number;
    len?: number;
    validator?: (rule: any, value: string) => Promise<void>;
};

export const getPhoneValidationRules = (callingCode: string): ValidationRule[] => {
    const countryCode = getCountryCodeFromCallingCode(callingCode);

    if (!countryCode) {
        throw new Error(`Invalid country code for calling code: ${callingCode}`);
    }

    return [
        {
            required: true,
            message: 'Enter phone number',
        },
        {
            pattern: /^[0-9]+$/,
            message: 'Phone number must be numeric',
        },
        {
            max: 15,
            message: 'Phone number cannot exceed 15 digits',
        },
        {
            validator: (_: any, value: string) =>
                isValidPhoneNumber(value, countryCode)
                    ? Promise.resolve()
                    : Promise.reject(new Error('Invalid phone number')),
        },
    ];
};


export const smsCodeValidationRules: ValidationRule[] = [
    {
        required: true,
        message: 'Enter verification code',
    },
    {
        pattern: /^[0-9]+$/,
        message: 'Verification code must be numeric',
    },
    {
        len: 6,
        message: 'Verification code must be exactly 6 digits',
    },
];
