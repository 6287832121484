import {ScannerActions} from '../action/scanner';


const initialState = {
    isLoading: false,
    decodedCarData : [],
    image: ''
}

const scanner = (state = initialState, action: any = {}) => {
    switch (action.type) {
        case ScannerActions.REQUEST_CAR_CHECK: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case ScannerActions.GET_CAR_DATA: {
            return {
                ...state,
                decodedCarData: action.payload
            }
        }
        case ScannerActions.DECODE_CAR_PHOTO: {
            return {
                ...state,
                decodedCarData: action.payload
            }
        }
        case ScannerActions.SET_IMAGE_DATA: {
            return {
                ...state,
                image: action.payload
            }
        }
        default:
            return state;
    }
}

export default scanner;