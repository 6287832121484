import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authCodeInputVisibleSelector } from '../../../redux/selector/serviceParametersSelector';
import { authLoading } from '../../../redux/selector/userSelector';
import { setAuthCodeInputVisibleAC } from '../../../redux/action/serviceParameters';
import { authUserAC } from '../../../redux/action/user';
import { AppDispatch } from '../../../redux/type/AppDispatch';
import useIsAuthenticated from '../../../security/hook/useIsAuthenticated';
import { usePhoneValidation } from '../../../util/phone/usePhoneValidation';
//component
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
//service
import { makeRandomKey } from '../../../util/Utilities';

import { PATHS } from '../../../routing/type/Path';
import { countryPhoneCodes } from './type/countryPhoneCodes';
import {
    getPhoneValidationRules,
    smsCodeValidationRules,
} from './type/validationRules';

import './auth.css';

const { Option } = Select;


const LoginPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [selectedPrefix, setSelectedPrefix] = useState<string>('+373');
    const { phoneNumber, handlePhoneNumberChange } =
        usePhoneValidation(selectedPrefix);

    const authIsLoading: boolean = useSelector(authLoading);

    const showCodeInput: boolean = useSelector(authCodeInputVisibleSelector);
    const from =
        (location.state as { from?: Location })?.from?.pathname ||
        PATHS.ROOT_PATH;

    useEffect(() => {
        if (isAuthenticated) {
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, from, navigate]);

    const handlePhoneSubmit = (): void => {
        dispatch(authUserAC(`${selectedPrefix}${phoneNumber}`));
    };

    const handleCodeSubmit = (): void => {
        dispatch(
            authUserAC(`${selectedPrefix}${phoneNumber}`, verificationCode)
        )
            .then((_r) => {
                dispatch(setAuthCodeInputVisibleAC(false));
            })
            .catch((_e) => {
                dispatch(setAuthCodeInputVisibleAC(false));
            });
    };

    const onChangePhoneNumber = (phone: string):void => {
        handlePhoneNumberChange(phone);
    };

    const countryCodeOptions = useMemo(
        () =>
            countryPhoneCodes?.map((code) => (
                <Option key={makeRandomKey()} value={code?.value}>
                    {code?.value}
                </Option>
            )),
        []
    );

    return (
        <Row>
            <Col xs={3} sm={5} md={7} lg={8} xl={9} />
            <Col xs={18} sm={14} md={10} lg={8} xl={6}>
                <Divider orientation='left'>{t('Enter')}</Divider>
                {!showCodeInput ? (
                    <Form onFinish={handlePhoneSubmit}>
                        <Form.Item
                            name='Phone number'
                            rules={getPhoneValidationRules(selectedPrefix)}
                        >
                            <Input
                                addonBefore={
                                    <Select
                                        defaultValue={selectedPrefix}
                                        value={selectedPrefix}
                                        onChange={setSelectedPrefix}>
                                        {countryCodeOptions}
                                    </Select>
                                }
                                disabled={authIsLoading}
                                value={phoneNumber}
                                onChange={(e) =>
                                    onChangePhoneNumber(e.target.value)
                                }
                                placeholder={t('Enter phone number')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className='iauto-auth-btn'
                                htmlType='submit'
                                type='primary'
                                loading={authIsLoading}
                            >
                                {t('Enter')}
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <Form onFinish={handleCodeSubmit}>
                        <Form.Item
                            name='SMS code'
                            rules={smsCodeValidationRules}>
                            <Input
                                disabled={authIsLoading}
                                value={verificationCode}
                                onChange={(e) =>
                                    setVerificationCode(e.target.value)
                                }
                                placeholder={t('Enter verification code')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className='iauto-auth-btn'
                                htmlType='submit'
                                type='primary'
                                loading={authIsLoading}>
                                {t('Verify')}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Col>
            <Col xs={3} sm={5} md={7} lg={8} xl={9} />
        </Row>
    );
};

export default LoginPage;
