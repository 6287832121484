import React, {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { activeRequestSelector } from '../../../redux/selector/requestSelector';
import { useSelector } from 'react-redux';
import { userDataSelector } from '../../../redux/selector/userSelector';
import { useTranslation } from 'react-i18next';
import { useIsRequestClosed } from '../../page/cabinet/hook/useIsRequestClosed';
import { useChatWebSocket } from '../../../websocket/useChatWebSocket';
// @ts-ignore
import { useSpeechRecognition } from 'react-speech-kit';
import { AuthenticatedUser, User } from '../../../entity/type/User';
import { API, HTTP_STATUS_CODE } from '../../../api/type/Api';
//component
import ChatHeader from './header/ChatHeader';
import ChatMessage from './message/ChatMessage';
import ChatMessageInput from './chat_input/ChatMessageInput';
//service
import { processImageFile } from '../../../util/image/ImageUtil';
import { makeRandomKey } from '../../../util/Utilities';
import { RcFile } from 'antd/es/upload';
import InputValidator from '../../../util/InputValidator';
import { ServiceParametersService } from '../../../util/ServiceParametersService';
import { DetailedMessage } from '../../../entity/type/DetailedMessage';
import { Offer } from '../../../entity/type/Offer';
import { ImagesService } from '../../../util/ImagesService';

import { MessageContentType } from '../../../entity/enums/MessageContentType';
import { MessageType } from './MessageType';
import { RequestService } from '../../../util/RequestService';
import { ChatRequest } from '../../../entity/type/ChatRequest';
import { Message } from '../../../entity/type/Message';

import './style/chat.css';

type ChatProps = {};

const Chat: FC<ChatProps> = () => {
    const activeRequest: ChatRequest = useSelector(activeRequestSelector);
    const requestIsClosed = useIsRequestClosed(activeRequest);
    const authenticatedUser: AuthenticatedUser = useSelector(userDataSelector);
    const currentUser: User = authenticatedUser?.user;
    const isCurrentUserClient: boolean = currentUser.id === activeRequest?.client?.id;
    const [messageText, setMessageText] = useState<string>('');
    const [messageImages] = useState<any>([]);
    const { t } = useTranslation();
    const [messageOffer] = useState<Offer | undefined>();

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const onMessageReceived = useCallback(
        (messageData: any) => {
            console.log('on receive', messageData);
            RequestService.updateRequestOnChatMessageReceive(messageData);
        },
        [activeRequest?.id, currentUser.id]
    );

    const handleSendMessage = async (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (!messageText.trim() && messageImages.length === 0) {
            return;
        }

        if (!e.shiftKey) {
            e.preventDefault(); // Prevent new line if Shift is not pressed
            const newMessage: DetailedMessage = createMessage();

            sendMessage(newMessage);
            setMessageText('');
        }
    };
    const { sendMessage } = useChatWebSocket(
        activeRequest?.id,
        onMessageReceived
    );

    const messages = useMemo(() => {
        return activeRequest?.messages?.items?.map((message: Message) => {
            const senderId: string = message.from;
            const sender: User =
                senderId === activeRequest?.client?.id
                    ? activeRequest.client
                    : activeRequest.operator;

            const senderName: string = sender ?
                `${sender.firstName} ${sender.lastName}` : '-';

            return (
                <ChatMessage
                    key={message.id}
                    type={
                        message.from === currentUser.id ?
                            MessageType.FROM : MessageType.TO
                    }
                    text={message.text}
                    img={message.images}
                    offer={message.offer}
                    dateReceived={message?.createdAt}
                    senderName={senderName}
                />
            );
        });
    }, [activeRequest?.messages]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);

    const createMessage = (): DetailedMessage => ({
        requestId: activeRequest.id,
        from: currentUser.id,
        to: isCurrentUserClient
            ? activeRequest?.operator?.id
            : activeRequest?.client?.id,
        images: messageImages,
        text: messageText,
        offer: messageOffer,
        messageType: MessageContentType.TEXT,
    });

    const messageTextUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setMessageText(e.target.value.trim() ? e.target.value : '');
    };

    const beforeMessageImageUpload = async (file: RcFile): Promise<boolean> => {
        try {
            const base64Image: string | null = await processImageFile(file);

            if (base64Image) {
                const messageImageData: object = {
                    request_id: activeRequest.id,
                    from: currentUser.id,
                    to: isCurrentUserClient ?
                        activeRequest?.operator?.id : activeRequest?.client?.id,
                    image_base64: base64Image,
                };

                await ImagesService.saveMessageImage(messageImageData);
                return true;
            }

            ServiceParametersService.processDataForNotificationModal({
                status: API.ERROR,
                code: HTTP_STATUS_CODE.UNSUPPORTED_MEDIA_TYPE.toString(),
                message: 'Wrong file type or size is too big',
                error: {
                    details: InputValidator.UPLOAD_FILE_FORMAT_ERROR_MESSAGE,
                },
            });
        } catch (error) {
            console.error('Error processing image:', error);

            ServiceParametersService.processDataForNotificationModal({
                status: API.ERROR,
                code: HTTP_STATUS_CODE.UNSUPPORTED_MEDIA_TYPE.toString(),
                message: 'Error processing image upload',
                error: {
                    details: InputValidator.UPLOAD_FILE_FORMAT_ERROR_MESSAGE,
                },
            });
        }

        return false;
    };

    const inputIsVisible = useCallback(() => {
        const requestInPreviewMode: boolean = activeRequest.isPreview ?
            activeRequest.isPreview : true;

        return !requestIsClosed() || !requestInPreviewMode;
    }, [activeRequest]);

    return (
        <>
            {activeRequest && (
                <div className='iauto-request-chat'>
                    <ChatHeader
                        userData={currentUser}
                        activeRequest={activeRequest}
                    />
                    <div
                        className='iauto-request-chat-content'
                        ref={messagesEndRef}>
                        <ChatMessage
                            key={makeRandomKey()}
                            type={MessageType.STEP}
                            text={t('Welcome to iAuto')}
                        />
                        {messages}
                    </div>
                    {inputIsVisible() && (
                        <ChatMessageInput
                            messageText={messageText}
                            messageImages={messageImages}
                            onMessageTextChange={messageTextUpdate}
                            onSendMessage={handleSendMessage}
                            onImageUpload={beforeMessageImageUpload}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default memo(Chat);
