import {
    authUserAC,
    authUserOnUniqueIdAC,
    logOutUserAC,
    setUserUpdateInProgressAC,
    updateUserDataAC
} from '../redux/action/user';
import {store} from "../redux/store";
import {AuthenticatedUser} from "../entity/type/User";
import LocalStorageService from "../security/LocalStorageService";
import {RootState} from "../redux/type/RootStore";


/**
 * Service to operate with user data
 */
class UserService {
    static authUser(phoneNumber: string, smsCode: string): void {
        // @ts-ignore
        store.dispatch(authUserAC(phoneNumber, smsCode));
    }

    static logOutUser():void {
        // @ts-ignore
        store.dispatch(logOutUserAC());
    }

    static getAuthHeaders(): object {
        const state: RootState = store.getState();
        const userData: AuthenticatedUser = state.user.userData;
        const requestHeaders: object = {
            'Content-Type': 'application/json',
            phone: userData?.auth?.phone,
            smsCode: userData?.auth?.smsCode,
            iAuto_token: userData?.auth?.token,
            'Authorization':userData?.auth?.imageAuthData?.tokenType+" "+userData?.auth?.imageAuthData?.accessToken
        };

        return requestHeaders;
    }

    /**
     * Register new user on uniqId if user does not exist
     */
    static registerNewUserOnUniqId(): void {
        LocalStorageService.getFromLocalStore(LocalStorageService.UNIQUE_ID)
            .then(uniqueId => {
                if (!uniqueId) authUserOnUniqueIdAC();
            })
    }

    static updateUserProfileData(userData: any): void {
        const state: RootState = store.getState();
        const userId: string = state.user.userData?.user?.id;

        // @ts-ignore
        store.dispatch(updateUserDataAC(userId, userData));
    }

    static setUserUpdateInProgressFlag(inProgress: boolean): void {
        store.dispatch(setUserUpdateInProgressAC(inProgress));
    }
}

export default UserService;