import { AsYouType, parsePhoneNumberFromString, CountryCode, getCountries } from 'libphonenumber-js';

/**
 * Validates a phone number for a given country code.
 * @param phoneNumber - The phone number to validate.
 * @param countryCode - The country code (e.g., 'MD', 'RO').
 * @returns true if the phone number is valid; false otherwise.
 */
export const isValidPhoneNumber = (phoneNumber: string, countryCode: string): boolean => {
    try {
        const parsed = parsePhoneNumberFromString(phoneNumber, countryCode as CountryCode); // Cast to CountryCode
        return !!parsed?.isValid();
    } catch {
        return false;
    }
};

/**
 * Checks if the provided country code is valid.
 * @param countryCode - The country code to validate.
 * @returns true if valid; false otherwise.
 */
export const isValidCountryCode = (countryCode: string): countryCode is CountryCode => {
    return getCountries().includes(countryCode as CountryCode);
};

/**
 * Formats a phone number as the user types.
 * @param phoneNumber - The phone number being typed.
 * @param countryCode - The country code (e.g., 'MD', 'RO').
 * @returns the formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber: string, countryCode: string): string => {
    const formatter = new AsYouType(countryCode as CountryCode);
    return formatter.input(phoneNumber);
};